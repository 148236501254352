import type { Broker } from '@investorean/types';

export const brokerLabels: { [name in Broker]: string } = {
  ib: 'Interactive Brokers',
  etoro: 'eToro',
  robinhood: 'Robinhood',
  naga: 'NAGA',
  cmeg: 'Capital Markets Elite Group',
};

export const brokerLinks: { [name in Broker]: string } = {
  ib: 'https://www.interactivebrokers.com',
  etoro: 'https://med.etoro.com/B12087_A114768_TClick_Setoro_homepage.aspx',
  robinhood: 'https://robinhood.com',
  naga: 'https://nagamarkets.com/',
  cmeg: 'https://portal.cmelitegroup.co.uk/register/in2023',
};
