import { isBrowser } from '../env';

interface DataLayerPushArguments {
  event: string;
  [name: string]: string | number;
}

declare global {
  interface Window {
    dataLayer?: Array<unknown>;
  }
}

class GoogleAnalytics {
  private dataLayer: Array<unknown> = (isBrowser() && window.dataLayer) || [];

  public push(arg: DataLayerPushArguments): void {
    this.dataLayer.push(arg);
  }
}

export default new GoogleAnalytics();
