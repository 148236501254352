import { type FC, useContext, createContext, type ReactNode } from 'react';
import useStatePersist from '../../hooks/useStatePersist';
import { isBrowser } from '../../utils/env';

export type Theme = 'light' | 'dark';

interface ThemeContextProps {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  isThemeLoaded: boolean;
}

const getPreferredScheme = () =>
  isBrowser() && window?.matchMedia?.('(prefers-color-scheme:dark)')?.matches
    ? 'dark'
    : 'light';

export const ThemeContext = createContext<ThemeContextProps>({
  theme: 'light',
  setTheme: () => {},
  isThemeLoaded: false,
});

export const ThemeContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme, isThemeLoaded] = useStatePersist<Theme>(
    'theme',
    getPreferredScheme(),
  );

  return (
    <ThemeContext.Provider
      value={{
        theme: isThemeLoaded ? theme : 'light',
        setTheme,
        isThemeLoaded,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextProps =>
  useContext<ThemeContextProps>(ThemeContext);
