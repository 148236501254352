import styled from 'styled-components';
import { mq } from '../../styles/mediaQueries';
import { themeColors } from '../../styles/colors';

export const FooterContent = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--i-background-tone);
  padding: 15px 40px;
  border-top: 1px solid var(--rs-border-primary);

  @media (${mq.tablet}) {
    flex-direction: column;
  }
`;

export const Navigation = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;

  @media (${mq.tablet}) {
    margin-bottom: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }

  li {
    display: block;
    margin-right: 20px;

    @media (${mq.tablet}) {
      margin: 5px;
    }

    a {
      color: var(--i-link-primary);
      white-space: nowrap;

      &:hover {
        color: var(--rs-primary-500);
      }
    }
  }
`;

export const SocialIcons = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
  transition: all 0.2s ease-in-out;
  gap: 10px;

  li {
    a {
      display: flex;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--i-link-primary);
      }

      &:hover svg {
        fill: var(--rs-primary-500);
      }
    }
  }
`;

export const LegalInformation = styled.div`
  color: var(--rs-gray-50);
  background-color: ${themeColors.light.primaryDark};
  padding: 5px 40px;
  display: flex;
  justify-content: end;
  font-size: 12px;

  @media (${mq.tablet}) {
    justify-content: center;
  }
`;
