import axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios';
import { setupCache, type CacheRequestConfig } from 'axios-cache-interceptor';
import { getAuth } from 'firebase/auth';
import { getToken } from 'firebase/app-check';
import { env } from './env';
import Logger from './Logger';
import { appCheck } from '../context/wrapRootElement';

class ApiClient {
  private client: AxiosInstance;

  public constructor() {
    const instance = axios.create({
      baseURL: `//${env.apiHost}`,
    });
    this.client = setupCache(instance, {
      methods: ['get', 'post', 'options', 'head'],
    });
  }

  private async getAppCheckToken(): Promise<string | null> {
    try {
      if (!appCheck) {
        throw new Error('AppCheck is not initialized');
      }
      const { token } = await getToken(appCheck, false);
      return token;
    } catch (e) {
      Logger.error(e);
      return null;
    }
  }

  public async post<REQ, RES>(
    url: string,
    data: REQ,
    config?: AxiosRequestConfig & CacheRequestConfig,
  ): Promise<AxiosResponse<RES>> {
    const appCheckToken = await this.getAppCheckToken();
    const userIdToken = await getAuth().currentUser?.getIdToken();
    const response = await this.client.post<REQ, AxiosResponse<RES>>(
      url,
      data,
      {
        ...config,
        headers: {
          ...config?.headers,
          ['X-Firebase-AppCheck'.toLowerCase()]:
            appCheckToken || 'token-placeholder',
          'inv-uid-token': userIdToken,
        },
      },
    );

    return response;
  }
}

export default new ApiClient();
