import styled from 'styled-components';
import { InputPicker as RInputPicker, Loader as RLoader } from 'rsuite';
import { Search } from '@rsuite/icons';

export const InputPicker = styled(RInputPicker)`
  &.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn,
  .rs-picker-toggle-textbox,
  .rs-picker-tag-wrapper .rs-picker-search .rs-picker-search-input {
    padding-left: 32px !important;
    padding-right: 10px !important;
  }

  .rs-picker-search-input {
    padding-left: 32px !important;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Loader = styled(RLoader)`
  display: inline-block;
  font-size: 20px;
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 5;
  pointer-events: none;
  color: var(--rs-text-primary);
`;

export const SearchIcon = styled(Search)`
  display: block;
  font-size: 20px;
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 5;
  pointer-events: none;
  color: var(--rs-text-primary);
`;

export const AssetLookupMenu = styled.div<{ $active?: boolean }>`
  .item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;

    .ticker {
      font-weight: 600;
      color: ${({ $active }) => ($active ? 'var(--rs-picker-value)' : 'var(--rs-text-heading)')};
    }

    .exchange {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;
