import { useCallback, useMemo, useState } from 'react';
import type {
  AssetsLookupRequest,
  AssetsLookupResponse,
  AssetsLookupResultItem,
} from '@investorean/types';
import api from '../../utils/apiClient';
import { useBroker } from '../../context/broker';
import Logger from '../../utils/Logger';
import { debounce } from 'lodash';
import { countryFlags } from '../../constants';
import { useFirebase } from '../../context/firebase';

export const useAssetsLookup = () => {
  const { staticConfig } = useFirebase();
  const [results, setResults] = useState<AssetsLookupResultItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { broker } = useBroker();

  const resultsMenu = useMemo(() => {
    return results.map((item) => {
      const countryFlag = item.countryCode && countryFlags[item.countryCode];
      const exchange = staticConfig.stocks.exchanges.find(
        (e) => e.code === item.exchange,
      )?.name;
      return {
        symbol: item.symbol,
        label: (
          <p className="item">
            <span className="ticker">{item.ticker}</span>
            {item.name ? <span className="name">({item.name})</span> : ''}
            {item.assetType === 'index' && countryFlag ? (
              <span className="country">{countryFlag}</span>
            ) : (
              ''
            )}
            {item.assetType === 'stock' && exchange ? (
              <span className="exchange">{exchange.slice(0, 4)}</span>
            ) : (
              ''
            )}
          </p>
        ),
      };
    });
  }, [results]);

  const search = useCallback(
    async (query?: string): Promise<void> => {
      if (!query) {
        setIsLoading(false);
        return;
      }

      if (isLoading) {
        return;
      }

      try {
        setIsLoading(true);
        const { data } = await api.post<
          AssetsLookupRequest,
          AssetsLookupResponse
        >('/assets/lookup', { query, broker: broker || undefined });
        setResults(data);
      } catch (error) {
        Logger.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [broker],
  );

  const lookup = useCallback(debounce(search, 300), [broker]);

  return { results, setResults, resultsMenu, isLoading, lookup };
};
