import type { AssetUnit } from '@investorean/types';

export const TOP_VALUED_ASSETS: AssetUnit[] = [
  {
    symbol: 'MSFT.US',
    assetType: 'stock',
  },
  {
    symbol: 'AAPL.US',
    assetType: 'stock',
  },
  {
    symbol: 'NVDA.US',
    assetType: 'stock',
  },
  {
    symbol: 'AMZN.US',
    assetType: 'stock',
  },
  {
    symbol: 'META.US',
    assetType: 'stock',
  },
  {
    symbol: 'GOOGL.US',
    assetType: 'stock',
  },
  {
    symbol: 'LLY.US',
    assetType: 'stock',
  },
  {
    symbol: 'TSM.US',
    assetType: 'stock',
  },
  {
    symbol: 'AVGO.US',
    assetType: 'stock',
  },
  {
    symbol: 'JPM.US',
    assetType: 'stock',
  },
  {
    symbol: 'TSLA.US',
    assetType: 'stock',
  },
  {
    symbol: 'NVO.US',
    assetType: 'stock',
  },
  {
    symbol: 'BRK-B.US',
    assetType: 'stock',
  },
  {
    symbol: 'XOM.US',
    assetType: 'stock',
  },
  {
    symbol: 'UNH.US',
    assetType: 'stock',
  },
  {
    symbol: 'MA.US',
    assetType: 'stock',
  },
  {
    symbol: 'V.US',
    assetType: 'stock',
  },
  {
    symbol: 'ASML.US',
    assetType: 'stock',
  },
  {
    symbol: 'HD.US',
    assetType: 'stock',
  },
  {
    symbol: 'PG.US',
    assetType: 'stock',
  },
  {
    symbol: 'JNJ.US',
    assetType: 'stock',
  },
  {
    symbol: 'ORCL.US',
    assetType: 'stock',
  },
  {
    symbol: 'TM.US',
    assetType: 'stock',
  },
  {
    symbol: 'MRK.US',
    assetType: 'stock',
  },
  {
    symbol: 'COST.US',
    assetType: 'stock',
  },
  {
    symbol: 'ABBV.US',
    assetType: 'stock',
  },
  {
    symbol: 'ADES.US',
    assetType: 'stock',
  },
  {
    symbol: 'BAC.US',
    assetType: 'stock',
  },
];
