import type { AssetType } from '@investorean/types';
import { INTERNAL_URIS } from '../constants/navigation';

export const getAssetDetailsUri = (
  symbol: string,
  assetType: AssetType,
): string => {
  switch (assetType) {
    case 'index':
      return `${INTERNAL_URIS.indexDetails.path}/${symbol}`;
    case 'currency':
      return `${INTERNAL_URIS.currencyDetails.path}/${symbol}`;
    case 'etf':
      return `${INTERNAL_URIS.etfDetails.path}/${symbol}`;
    default:
      return `${INTERNAL_URIS.stockDetails.path}/${symbol}`;
  }
};

export const getAssetDetailsUriTitle = (assetType: AssetType): string => {
  switch (assetType) {
    case 'index':
      return INTERNAL_URIS.indexDetails.title;
    case 'currency':
      return INTERNAL_URIS.currencyDetails.title;
    case 'etf':
      return INTERNAL_URIS.etfDetails.title;
    default:
      return INTERNAL_URIS.stockDetails.title;
  }
};
