import ga from './ga';
import uet from './uet';
import qp from './qp';
import type { EventName, FireOptions } from './types';

class Analytics {
  public fire(event: EventName, options?: FireOptions): void {
    ga.push({ event, ...options });
    uet.push('event', event, options);
    qp.push('track', event);
  }
}

export default new Analytics();
