import type { FC } from 'react';
import type { WrapRootElementBrowserArgs } from 'gatsby';
import {
  browserTracingIntegration,
  init as initSentry,
  replayIntegration,
} from '@sentry/gatsby';
import { initializeApp, getApp } from 'firebase/app';
import {
  initializeAuth,
  indexedDBLocalPersistence,
  browserLocalPersistence,
  browserPopupRedirectResolver,
} from 'firebase/auth';
import { initializeFirestore } from 'firebase/firestore';
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  type AppCheck,
} from 'firebase/app-check';
import 'firebase/functions';
import 'firebase/remote-config';
import 'firebase/storage';

import { FirebaseProvider } from './firebase';
import { isBrowser, env, isDevelopment } from '../utils/env';
import { ThemeContextProvider } from './theme';
import { ThemeColorsLight } from '../styles/colors';

const CONSOLE_MESSAGE = `
=========================================================================================

██ ███    ██ ██    ██ ███████ ███████ ████████  ██████  ██████  ███████  █████  ███    ██
██ ████   ██ ██    ██ ██      ██         ██    ██    ██ ██   ██ ██      ██   ██ ████   ██
██ ██ ██  ██ ██    ██ █████   ███████    ██    ██    ██ ██████  █████   ███████ ██ ██  ██
██ ██  ██ ██  ██  ██  ██           ██    ██    ██    ██ ██   ██ ██      ██   ██ ██  ██ ██
██ ██   ████   ████   ███████ ███████    ██     ██████  ██   ██ ███████ ██   ██ ██   ████

=========================================================================================

If you found a bug or inaccuracy in our data, we kindly ask you to report it here -> https://investorean.com/contact

`;

initializeApp({
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
});

export let appCheck: AppCheck | null = null;

if (isBrowser()) {
  if (isDevelopment() && typeof self !== 'undefined') {
    // @ts-ignore
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }
  appCheck = initializeAppCheck(getApp(), {
    provider: new ReCaptchaV3Provider(env.recaptchaSiteKey),
    isTokenAutoRefreshEnabled: true,
  });
  initializeAuth(getApp(), {
    persistence: [indexedDBLocalPersistence, browserLocalPersistence],
    popupRedirectResolver: browserPopupRedirectResolver,
  });
  initializeFirestore(getApp(), {
    ignoreUndefinedProperties: true,
  });
  if (process.env.GATSBY_SENTRY_DSN) {
    initSentry({
      dsn: process.env.GATSBY_SENTRY_DSN,
      environment: process.env.GATSBY_ENV,
      release: process.env.GATSBY_GITHUB_SHA,
      integrations: [browserTracingIntegration(), replayIntegration()],
      tracesSampleRate: 0.5,
      replaysSessionSampleRate: 0.5,
      replaysOnErrorSampleRate: 1.0,
    });
  }
}

export const wrapRootElement: FC<WrapRootElementBrowserArgs> = ({
  element,
}) => {
  if (isBrowser()) {
    console.info(CONSOLE_MESSAGE);
  }

  return (
    <FirebaseProvider appCheck={appCheck}>
      <ThemeColorsLight />
      <ThemeContextProvider>{element}</ThemeContextProvider>
    </FirebaseProvider>
  );
};
