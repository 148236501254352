import type { Etf, Index, StaticConfig, Stock } from '@investorean/types';
import type { Filter } from './types/filters';
import type { StockScreenerFieldNames } from './components/StocksScreener/types';
import type { EtfScreenerFieldNames } from './components/EtfsScreener/types';

export const renderPrefix = ({
  prefix,
  currencyCode,
  staticConfig,
}: {
  prefix?: string;
  currencyCode: string | null;
  staticConfig?: StaticConfig;
}): string => {
  if (!prefix || !currencyCode || !staticConfig) {
    return '';
  }
  switch (prefix) {
    case '%currency%':
      return (
        staticConfig.stocks.currencies.find((c) => c.code === currencyCode)
          ?.symbol || ''
      );
    default:
      return prefix;
  }
};

export const selectorFilters: Filter[] = [
  {
    label: 'Lower',
    value: '<',
    type: 'value',
  },
  {
    label: 'Higher',
    value: '>',
    type: 'value',
  },
  {
    label: 'Equal',
    value: '=',
    type: 'value',
  },
  {
    label: 'Between',
    value: '&',
    type: 'range',
  },
];

export const weekTabs = [
  {
    label: 'below High',
    id: 1,
    slider: true,
    value: 'week52LowDiff',
  },
  {
    label: 'above High',
    id: 2,
    slider: true,
    value: 'week52HighDiff',
  },
  {
    label: 'new High',
    id: 3,
    slider: false,
    value: 'week52NewHigh',
  },
  {
    label: 'new Low',
    id: 4,
    slider: false,
    value: 'week52NewLow',
  },
];

export type StockLabelNames = keyof Stock | StockScreenerFieldNames;

export const stockFieldsLabels: {
  [name in StockLabelNames]: {
    label: string;
    labelHTML: string;
    prefix?: string;
    suffix?: string;
  };
} = {
  broker: {
    label: 'Broker',
    labelHTML: 'Broker',
  },
  symbol: {
    label: 'Ticker',
    labelHTML: 'Ticker',
  },
  symbolSorted: {
    label: 'Ticker',
    labelHTML: 'Ticker',
  },
  ticker: {
    label: 'Ticker',
    labelHTML: 'Ticker',
  },
  countryCode: {
    label: 'Country',
    labelHTML: 'Country',
  },
  currencyCode: {
    label: 'Currency',
    labelHTML: 'Currency',
  },
  companyName: {
    label: 'Company',
    labelHTML: 'Company',
  },
  exchange: {
    label: 'Exchange',
    labelHTML: 'Exchange',
  },
  sector: {
    label: 'Sector',
    labelHTML: 'Sector',
  },
  industry: {
    label: 'Industry',
    labelHTML: 'Industry',
  },
  marketCap: {
    label: 'Market Cap.',
    labelHTML: 'Market Cap.',
  },
  avg30Volume: {
    label: 'Avg. Volume (30D)',
    labelHTML: 'Avg. Volume (30D)',
  },
  avg10Volume: {
    label: 'Avg. Volume (10D)',
    labelHTML: 'Avg. Volume (10D)',
  },
  latestVolume: {
    label: 'Volume (last day)',
    labelHTML: 'Volume (last day)',
  },
  relativeVolume: {
    label: 'Relative Volume',
    labelHTML: 'Relative Volume',
  },
  price: {
    label: 'Price',
    labelHTML: 'Price',
  },
  priceUSD: {
    label: 'Price ($)',
    labelHTML: 'Price ($)',
  },
  day1ChangePercent: {
    label: '1D Chg. (%)',
    labelHTML: 'Price Change (1D)',
    suffix: '%',
  },
  day5ChangePercent: {
    label: '5D Chg. (%)',
    labelHTML: 'Price Change (5D)',
    suffix: '%',
  },
  day50MovingAvgDiff: {
    label: 'Diff to 50-Day SMA',
    labelHTML: 'Diff to 50-Day SMA',
    suffix: '%',
  },
  day200MovingAvgDiff: {
    label: 'Diff to 200-Day SMA',
    labelHTML: 'Diff to 200-Day SMA',
    suffix: '%',
  },
  description: {
    label: 'Description',
    labelHTML: 'Description',
  },
  nextEarningsDate: {
    label: 'Next Earnings Date',
    labelHTML: 'Next Earnings Date',
  },
  sharesOutstanding: {
    label: 'Shares Outstanding',
    labelHTML: 'Shares Outstanding',
  },
  employees: {
    label: 'Employees',
    labelHTML: 'Employees',
  },
  month1ChangePercent: {
    label: '1M Chg. (%)',
    labelHTML: 'Price Change (1M)',
    suffix: '%',
  },
  month3ChangePercent: {
    label: '3M Chg. (%)',
    labelHTML: 'Price Change (3M)',
    suffix: '%',
  },
  month6ChangePercent: {
    label: '6M Chg. (%)',
    labelHTML: 'Price Change (6M)',
    suffix: '%',
  },
  week52HighDiff: {
    label: 'Diff. to High (52W)',
    labelHTML: 'Diff. to High (52W)',
    suffix: '%',
  },
  week52LowDiff: {
    label: 'Diff. to Low (52W)',
    labelHTML: 'Diff. to Low (52W)',
    suffix: '%',
  },
  week52NewHigh: {
    label: 'New High (52W)',
    labelHTML: 'New High (52W)',
  },
  week52NewLow: {
    label: 'New Low (52W)',
    labelHTML: 'New Low (52W)',
  },
  year1ChangePercent: {
    label: '1Y Chg. (%)',
    labelHTML: 'Price Change (1Y)',
    suffix: '%',
  },
  year2ChangePercent: {
    label: '2Y Chg. (%)',
    labelHTML: 'Price Change (2Y)',
    suffix: '%',
  },
  year5ChangePercent: {
    label: '5Y Chg. (%)',
    labelHTML: 'Price Change (5Y)',
    suffix: '%',
  },
  ytdChangePercent: {
    label: 'YTD Chg. (%)',
    labelHTML: 'Price Change (YTD)',
    suffix: '%',
  },
  website: {
    label: 'Website',
    labelHTML: 'Website',
  },
  peRatio: {
    label: 'P/E ratio',
    labelHTML: 'P/E ratio',
  },
  pegRatio: {
    label: 'PEG ratio',
    labelHTML: 'PEG ratio',
  },
  beta: {
    label: 'Beta',
    labelHTML: 'Beta',
  },
  earningsPerShareTTM: {
    label: 'EPS',
    labelHTML: 'EPS',
    prefix: '%currency%',
  },
  dividendYield: {
    label: 'Dividend Yield',
    labelHTML: 'Dividend Yield',
    suffix: '%',
  },
  dividendRateTTM: {
    label: 'Dividend Rate (TTM)',
    labelHTML: 'Dividend Rate (TTM)',
  },
  brokers: {
    label: 'Brokers',
    labelHTML: 'Brokers',
  },
  week52HighLow: {
    label: '52 Weeks High/Low',
    labelHTML: '52 Weeks High/Low',
    suffix: '%',
  },
  dividendAmount: {
    label: 'Dividend Amount',
    labelHTML: 'Dividend Amount',
  },
  dividendCurrencyCode: {
    label: 'Dividend Currency',
    labelHTML: 'Dividend Currency',
  },
  dividendDeclaredDate: {
    label: 'Dividend Declared Date',
    labelHTML: 'Dividend Declared Date',
  },
  dividendExDate: {
    label: 'Ex-Dividend Date',
    labelHTML: 'Ex-Dividend Date',
  },
  dividendFlag: {
    label: 'Dividend Payment Method',
    labelHTML: 'Dividend Payment Method',
  },
  dividendFrequency: {
    label: 'Dividends Frequency',
    labelHTML: 'Dividends Frequency',
  },
  dividendPaymentDate: {
    label: 'Dividend Payment Date',
    labelHTML: 'Dividend Payment Date',
  },
  dividendRecordDate: {
    label: 'Dividend Record Date',
    labelHTML: 'Dividend Record Date',
  },
  dividendPayoutRatio: {
    label: 'Dividend Payout Ratio',
    labelHTML: 'Dividend Payout Ratio',
  },
  profitMargin: {
    label: 'Profit Margin',
    labelHTML: 'Profit Margin',
    suffix: '%',
  },
  operatingMarginTTM: {
    label: 'Operating Margin (TTM)',
    labelHTML: 'Operating Margin (TTM)',
    suffix: '%',
  },
  revenueTTM: {
    label: 'Revenue (TTM)',
    labelHTML: 'Revenue (TTM)',
  },
  costOfRevenueTTM: {
    label: 'Cost of Revenue (TTM)',
    labelHTML: 'Cost of Revenue (TTM)',
  },
  grossProfitTTM: {
    label: 'Gross Profit (TTM)',
    labelHTML: 'Gross Profit (TTM)',
  },
  grossMarginTTM: {
    label: 'Gross Margin (TTM)',
    labelHTML: 'Gross Margin (TTM)',
    suffix: '%',
  },
  quarterlyRevenueGrowthYOY: {
    label: 'Quarterly Revenue Growth (YOY)',
    labelHTML: 'Quarterly Revenue<br />Growth (YOY)',
    suffix: '%',
  },
  quarterlyEarningsGrowthYOY: {
    label: 'Quarterly Earnings Growth (YOY)',
    labelHTML: 'Quarterly Earnings<br />Growth (YOY)',
    suffix: '%',
  },
  debtToEquityMRQ: {
    label: 'Debt/Equity (MRQ)',
    labelHTML: 'Debt/Equity (MRQ)',
    suffix: '%',
  },
  returnOnAssetsTTM: {
    label: 'Return on Assets (TTM)',
    labelHTML: 'Return on<br />Assets (TTM)',
    suffix: '%',
  },
  returnOnEquityTTM: {
    label: 'Return on Equity (TTM)',
    labelHTML: 'Return on<br />Equity (TTM)',
    suffix: '%',
  },
  ipoDate: {
    label: 'IPO Date',
    labelHTML: 'IPO Date',
  },
  logoUrl: {
    label: 'Logo',
    labelHTML: 'Logo',
  },
  dividendIncreaseStreak: {
    label: 'Dividend Increase Streak',
    labelHTML: 'Dividend Increase Streak',
  },
};

export type EtfLabelNames = keyof Etf | EtfScreenerFieldNames;

export const etfFieldsLabels: {
  [name in EtfLabelNames]: {
    label: string;
    labelHTML: string;
    prefix?: string;
    suffix?: string;
  };
} = {
  broker: {
    label: 'Broker',
    labelHTML: 'Broker',
  },
  symbol: {
    label: 'Ticker',
    labelHTML: 'Ticker',
  },
  ticker: {
    label: 'Ticker',
    labelHTML: 'Ticker',
  },
  countryCode: {
    label: 'Country',
    labelHTML: 'Country',
  },
  currencyCode: {
    label: 'Currency',
    labelHTML: 'Currency',
  },
  name: {
    label: 'Name',
    labelHTML: 'Name',
  },
  category: {
    label: 'Category',
    labelHTML: 'Category',
  },
  price: {
    label: 'Price',
    labelHTML: 'Price',
  },
  priceUSD: {
    label: 'Price ($)',
    labelHTML: 'Price ($)',
  },
  latestVolume: {
    label: 'Latest Volume',
    labelHTML: 'Latest Volume',
  },
  avg30Volume: {
    label: 'Avg. Volume (30D)',
    labelHTML: 'Avg. Volume (30D)',
  },
  relativeVolume: {
    label: 'Relative Volume',
    labelHTML: 'Relative Volume',
  },
  fundamentals: {
    label: '',
    labelHTML: '',
  },
  quotes: {
    label: '',
    labelHTML: '',
  },
  inceptionDate: {
    label: 'Inception Date',
    labelHTML: 'Inception Date',
  },
  mainAssetClass: {
    label: 'Main Asset Class',
    labelHTML: 'Main Asset Class',
  },
  mainRegion: {
    label: 'Main Region',
    labelHTML: 'Main Region',
  },
  mainSector: {
    label: 'Main Sector',
    labelHTML: 'Main Sector',
  },
  netExpenseRatioPercent: {
    label: 'Expense Ratio',
    labelHTML: 'Expense Ratio',
    suffix: '%',
  },
  totalAssets: {
    label: 'Total Assets',
    labelHTML: 'Total Assets',
  },
  day1ChangePercent: {
    label: '1D Chg. (%)',
    labelHTML: 'Price Change (1D)',
    suffix: '%',
  },
  day5ChangePercent: {
    label: '5D Chg. (%)',
    labelHTML: 'Price Change (5D)',
    suffix: '%',
  },
  month1ChangePercent: {
    label: '1M Chg. (%)',
    labelHTML: 'Price Change (1M)',
    suffix: '%',
  },
  month3ChangePercent: {
    label: '3M Chg. (%)',
    labelHTML: 'Price Change (3M)',
    suffix: '%',
  },
  month6ChangePercent: {
    label: '6M Chg. (%)',
    labelHTML: 'Price Change (6M)',
    suffix: '%',
  },
  year1ChangePercent: {
    label: '1Y Chg. (%)',
    labelHTML: 'Price Change (1Y)',
    suffix: '%',
  },
  year5ChangePercent: {
    label: '5Y Chg. (%)',
    labelHTML: 'Price Change (5Y)',
    suffix: '%',
  },
  ytdChangePercent: {
    label: 'YTD Chg. (%)',
    labelHTML: 'Price Change (YTD)',
    suffix: '%',
  },
  dividendYield: {
    label: 'Dividend Yield',
    labelHTML: 'Dividend Yield',
    suffix: '%',
  },
  brokers: {
    label: 'Brokers',
    labelHTML: 'Brokers',
  },
};

export const indexFieldsLabels: {
  [name in keyof Index]: {
    label: string;
    labelHTML: string;
    prefix?: string;
    suffix?: string;
  };
} = {
  symbol: {
    label: 'Ticker',
    labelHTML: 'Ticker',
  },
  ticker: {
    label: 'Ticker',
    labelHTML: 'Ticker',
  },
  countryCode: {
    label: 'Country',
    labelHTML: 'Country',
  },
  currencyCode: {
    label: 'Currency',
    labelHTML: 'Currency',
  },
  name: {
    label: 'Name',
    labelHTML: 'Name',
  },
  exchange: {
    label: 'Exchange',
    labelHTML: 'Exchange',
  },
  price: {
    label: 'Price',
    labelHTML: 'Price',
  },
  priceUSD: {
    label: 'Price ($)',
    labelHTML: 'Price ($)',
  },
  day1ChangePercent: {
    label: '1D Chg. (%)',
    labelHTML: 'Price Change (1D)',
    suffix: '%',
  },
  day5ChangePercent: {
    label: '5D Chg. (%)',
    labelHTML: 'Price Change (5D)',
    suffix: '%',
  },
  month1ChangePercent: {
    label: '1M Chg. (%)',
    labelHTML: 'Price Change (1M)',
    suffix: '%',
  },
  month3ChangePercent: {
    label: '3M Chg. (%)',
    labelHTML: 'Price Change (3M)',
    suffix: '%',
  },
  month6ChangePercent: {
    label: '6M Chg. (%)',
    labelHTML: 'Price Change (6M)',
    suffix: '%',
  },
  year1ChangePercent: {
    label: '1Y Chg. (%)',
    labelHTML: 'Price Change (1Y)',
    suffix: '%',
  },
  year5ChangePercent: {
    label: '5Y Chg. (%)',
    labelHTML: 'Price Change (5Y)',
    suffix: '%',
  },
  ytdChangePercent: {
    label: 'YTD Chg. (%)',
    labelHTML: 'Price Change (YTD)',
    suffix: '%',
  },
  brokers: {
    label: 'Brokers',
    labelHTML: 'Brokers',
  },
  priority: {
    label: '',
    labelHTML: '',
  },
  components: {
    label: '',
    labelHTML: '',
  },
  quotes: {
    label: '',
    labelHTML: '',
  },
};

export const exchangeFlags: {
  [exchange: string]: string;
} = {
  BMEX: '🇪🇸',
  OTCM: '🇺🇸',
  XAMS: '🇳🇱',
  XASE: '🇺🇸',
  XASX: '🇦🇺',
  XBRU: '🇧🇪',
  XCSE: '🇩🇰',
  XFRA: '🇩🇪',
  XHEL: '🇫🇮',
  XHKG: '🇭🇰',
  XLIS: '🇵🇹',
  XLON: '🇬🇧',
  XMIL: '🇮🇹',
  XNAS: '🇺🇸',
  XNYS: '🇺🇸',
  XOSL: '🇳🇴',
  XPAR: '🇫🇷',
  XSAU: '🇸🇦',
  XSTO: '🇸🇪',
  XSWX: '🇨🇭',
};

export const exchangeCodeFlags: {
  [exchange: string]: string;
} = {
  US: '🇺🇸',
  AS: '🇳🇱',
  HK: '🇭🇰',
  MC: '🇪🇸',
  MI: '🇮🇹',
  F: '🇩🇪',
  BR: '🇧🇪',
  LS: '🇵🇹',
  OL: '🇳🇴',
  PA: '🇫🇷',
  LSE: '🇬🇧',
  CO: '🇩🇰',
  HE: '🇫🇮',
  ST: '🇸🇪',
  SW: '🇨🇭',
  SR: '🇸🇦',
  AU: '🇦🇺',
};

export const countryFlags: {
  [countryCode: string]: string;
} = {
  AD: '🇦🇩',
  AE: '🇦🇪',
  AF: '🇦🇫',
  AG: '🇦🇬',
  AI: '🇦🇮',
  AL: '🇦🇱',
  AM: '🇦🇲',
  AO: '🇦🇴',
  AQ: '🇦🇶',
  AR: '🇦🇷',
  AS: '🇦🇸',
  AT: '🇦🇹',
  AU: '🇦🇺',
  AW: '🇦🇼',
  AX: '🇦🇽',
  AZ: '🇦🇿',
  BA: '🇧🇦',
  BB: '🇧🇧',
  BD: '🇧🇩',
  BE: '🇧🇪',
  BF: '🇧🇫',
  BG: '🇧🇬',
  BH: '🇧🇭',
  BI: '🇧🇮',
  BJ: '🇧🇯',
  BL: '🇧🇱',
  BM: '🇧🇲',
  BN: '🇧🇳',
  BO: '🇧🇴',
  BQ: '🇧🇶',
  BR: '🇧🇷',
  BS: '🇧🇸',
  BT: '🇧🇹',
  BV: '🇧🇻',
  BW: '🇧🇼',
  BY: '🇧🇾',
  BZ: '🇧🇿',
  CA: '🇨🇦',
  CC: '🇨🇨',
  CD: '🇨🇩',
  CF: '🇨🇫',
  CG: '🇨🇬',
  CH: '🇨🇭',
  CI: '🇨🇮',
  CK: '🇨🇰',
  CL: '🇨🇱',
  CM: '🇨🇲',
  CN: '🇨🇳',
  CO: '🇨🇴',
  CR: '🇨🇷',
  CU: '🇨🇺',
  CV: '🇨🇻',
  CW: '🇨🇼',
  CX: '🇨🇽',
  CY: '🇨🇾',
  CZ: '🇨🇿',
  DE: '🇩🇪',
  DJ: '🇩🇯',
  DK: '🇩🇰',
  DM: '🇩🇲',
  DO: '🇩🇴',
  DZ: '🇩🇿',
  EC: '🇪🇨',
  EE: '🇪🇪',
  EG: '🇪🇬',
  EH: '🇪🇭',
  ER: '🇪🇷',
  ES: '🇪🇸',
  ET: '🇪🇹',
  EU: '🇪🇺',
  FI: '🇫🇮',
  FJ: '🇫🇯',
  FK: '🇫🇰',
  FM: '🇫🇲',
  FO: '🇫🇴',
  FR: '🇫🇷',
  GA: '🇬🇦',
  GB: '🇬🇧',
  GD: '🇬🇩',
  GE: '🇬🇪',
  GF: '🇬🇫',
  GG: '🇬🇬',
  GH: '🇬🇭',
  GI: '🇬🇮',
  GL: '🇬🇱',
  GM: '🇬🇲',
  GN: '🇬🇳',
  GP: '🇬🇵',
  GQ: '🇬🇶',
  GR: '🇬🇷',
  GS: '🇬🇸',
  GT: '🇬🇹',
  GU: '🇬🇺',
  GW: '🇬🇼',
  GY: '🇬🇾',
  HK: '🇭🇰',
  HM: '🇭🇲',
  HN: '🇭🇳',
  HR: '🇭🇷',
  HT: '🇭🇹',
  HU: '🇭🇺',
  ID: '🇮🇩',
  IE: '🇮🇪',
  IL: '🇮🇱',
  IM: '🇮🇲',
  IN: '🇮🇳',
  IO: '🇮🇴',
  IQ: '🇮🇶',
  IR: '🇮🇷',
  IS: '🇮🇸',
  IT: '🇮🇹',
  JE: '🇯🇪',
  JM: '🇯🇲',
  JO: '🇯🇴',
  JP: '🇯🇵',
  KE: '🇰🇪',
  KG: '🇰🇬',
  KH: '🇰🇭',
  KI: '🇰🇮',
  KM: '🇰🇲',
  KN: '🇰🇳',
  KP: '🇰🇵',
  KR: '🇰🇷',
  KW: '🇰🇼',
  KY: '🇰🇾',
  KZ: '🇰🇿',
  LA: '🇱🇦',
  LB: '🇱🇧',
  LC: '🇱🇨',
  LI: '🇱🇮',
  LK: '🇱🇰',
  LR: '🇱🇷',
  LS: '🇱🇸',
  LT: '🇱🇹',
  LU: '🇱🇺',
  LV: '🇱🇻',
  LY: '🇱🇾',
  MA: '🇲🇦',
  MC: '🇲🇨',
  MD: '🇲🇩',
  ME: '🇲🇪',
  MF: '🇲🇫',
  MG: '🇲🇬',
  MH: '🇲🇭',
  MK: '🇲🇰',
  ML: '🇲🇱',
  MM: '🇲🇲',
  MN: '🇲🇳',
  MO: '🇲🇴',
  MP: '🇲🇵',
  MQ: '🇲🇶',
  MR: '🇲🇷',
  MS: '🇲🇸',
  MT: '🇲🇹',
  MU: '🇲🇺',
  MV: '🇲🇻',
  MW: '🇲🇼',
  MX: '🇲🇽',
  MY: '🇲🇾',
  MZ: '🇲🇿',
  NA: '🇳🇦',
  NC: '🇳🇨',
  NE: '🇳🇪',
  NF: '🇳🇫',
  NG: '🇳🇬',
  NI: '🇳🇮',
  NL: '🇳🇱',
  NO: '🇳🇴',
  NP: '🇳🇵',
  NR: '🇳🇷',
  NU: '🇳🇺',
  NZ: '🇳🇿',
  OM: '🇴🇲',
  PA: '🇵🇦',
  PE: '🇵🇪',
  PF: '🇵🇫',
  PG: '🇵🇬',
  PH: '🇵🇭',
  PK: '🇵🇰',
  PL: '🇵🇱',
  PM: '🇵🇲',
  PN: '🇵🇳',
  PR: '🇵🇷',
  PS: '🇵🇸',
  PT: '🇵🇹',
  PW: '🇵🇼',
  PY: '🇵🇾',
  QA: '🇶🇦',
  RE: '🇷🇪',
  RO: '🇷🇴',
  RS: '🇷🇸',
  RU: '🇷🇺',
  RW: '🇷🇼',
  SA: '🇸🇦',
  SB: '🇸🇧',
  SC: '🇸🇨',
  SD: '🇸🇩',
  SE: '🇸🇪',
  SG: '🇸🇬',
  SH: '🇸🇭',
  SI: '🇸🇮',
  SJ: '🇸🇯',
  SK: '🇸🇰',
  SL: '🇸🇱',
  SM: '🇸🇲',
  SN: '🇸🇳',
  SO: '🇸🇴',
  SR: '🇸🇷',
  SS: '🇸🇸',
  ST: '🇸🇹',
  SV: '🇸🇻',
  SX: '🇸🇽',
  SY: '🇸🇾',
  SZ: '🇸🇿',
  TC: '🇹🇨',
  TD: '🇹🇩',
  TF: '🇹🇫',
  TG: '🇹🇬',
  TH: '🇹🇭',
  TJ: '🇹🇯',
  TK: '🇹🇰',
  TL: '🇹🇱',
  TM: '🇹🇲',
  TN: '🇹🇳',
  TO: '🇹🇴',
  TR: '🇹🇷',
  TT: '🇹🇹',
  TV: '🇹🇻',
  TW: '🇹🇼',
  TZ: '🇹🇿',
  UA: '🇺🇦',
  UG: '🇺🇬',
  UM: '🇺🇲',
  US: '🇺🇸',
  UY: '🇺🇾',
  UZ: '🇺🇿',
  VA: '🇻🇦',
  VC: '🇻🇨',
  VE: '🇻🇪',
  VG: '🇻🇬',
  VI: '🇻🇮',
  VN: '🇻🇳',
  VU: '🇻🇺',
  WF: '🇼🇫',
  WS: '🇼🇸',
  XK: '🇽🇰',
  YE: '🇾🇪',
  YT: '🇾🇹',
  ZA: '🇿🇦',
  ZM: '🇿🇲',
  ZW: '🇿🇼',
};

export const ALL_COUNTRIES: {
  code: string;
  name: string;
  flag: string;
}[] = [
  {
    code: 'GS',
    name: 'South Georgia',
    flag: '🇬🇸',
  },
  {
    code: 'GD',
    name: 'Grenada',
    flag: '🇬🇩',
  },
  {
    code: 'CH',
    name: 'Switzerland',
    flag: '🇨🇭',
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
    flag: '🇸🇱',
  },
  {
    code: 'HU',
    name: 'Hungary',
    flag: '🇭🇺',
  },
  {
    code: 'TW',
    name: 'Taiwan',
    flag: '🇹🇼',
  },
  {
    code: 'WF',
    name: 'Wallis and Futuna',
    flag: '🇼🇫',
  },
  {
    code: 'BB',
    name: 'Barbados',
    flag: '🇧🇧',
  },
  {
    code: 'PN',
    name: 'Pitcairn Islands',
    flag: '🇵🇳',
  },
  {
    code: 'CI',
    name: 'Ivory Coast',
    flag: '🇨🇮',
  },
  {
    code: 'TN',
    name: 'Tunisia',
    flag: '🇹🇳',
  },
  {
    code: 'IT',
    name: 'Italy',
    flag: '🇮🇹',
  },
  {
    code: 'BJ',
    name: 'Benin',
    flag: '🇧🇯',
  },
  {
    code: 'ID',
    name: 'Indonesia',
    flag: '🇮🇩',
  },
  {
    code: 'CV',
    name: 'Cape Verde',
    flag: '🇨🇻',
  },
  {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
    flag: '🇰🇳',
  },
  {
    code: 'LA',
    name: 'Laos',
    flag: '🇱🇦',
  },
  {
    code: 'BQ',
    name: 'Caribbean Netherlands',
    flag: '🇧🇶',
  },
  {
    code: 'UG',
    name: 'Uganda',
    flag: '🇺🇬',
  },
  {
    code: 'AD',
    name: 'Andorra',
    flag: '🇦🇩',
  },
  {
    code: 'BI',
    name: 'Burundi',
    flag: '🇧🇮',
  },
  {
    code: 'ZA',
    name: 'South Africa',
    flag: '🇿🇦',
  },
  {
    code: 'FR',
    name: 'France',
    flag: '🇫🇷',
  },
  {
    code: 'LY',
    name: 'Libya',
    flag: '🇱🇾',
  },
  {
    code: 'MX',
    name: 'Mexico',
    flag: '🇲🇽',
  },
  {
    code: 'GA',
    name: 'Gabon',
    flag: '🇬🇦',
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
    flag: '🇲🇵',
  },
  {
    code: 'MK',
    name: 'North Macedonia',
    flag: '🇲🇰',
  },
  {
    code: 'CN',
    name: 'China',
    flag: '🇨🇳',
  },
  {
    code: 'YE',
    name: 'Yemen',
    flag: '🇾🇪',
  },
  {
    code: 'BL',
    name: 'Saint Barthélemy',
    flag: '🇧🇱',
  },
  {
    code: 'GG',
    name: 'Guernsey',
    flag: '🇬🇬',
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
    flag: '🇸🇧',
  },
  {
    code: 'SJ',
    name: 'Svalbard and Jan Mayen',
    flag: '🇸🇯',
  },
  {
    code: 'FO',
    name: 'Faroe Islands',
    flag: '🇫🇴',
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
    flag: '🇺🇿',
  },
  {
    code: 'EG',
    name: 'Egypt',
    flag: '🇪🇬',
  },
  {
    code: 'SN',
    name: 'Senegal',
    flag: '🇸🇳',
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
    flag: '🇱🇰',
  },
  {
    code: 'PS',
    name: 'Palestine',
    flag: '🇵🇸',
  },
  {
    code: 'BD',
    name: 'Bangladesh',
    flag: '🇧🇩',
  },
  {
    code: 'PE',
    name: 'Peru',
    flag: '🇵🇪',
  },
  {
    code: 'SG',
    name: 'Singapore',
    flag: '🇸🇬',
  },
  {
    code: 'TR',
    name: 'Turkey',
    flag: '🇹🇷',
  },
  {
    code: 'AF',
    name: 'Afghanistan',
    flag: '🇦🇫',
  },
  {
    code: 'AW',
    name: 'Aruba',
    flag: '🇦🇼',
  },
  {
    code: 'CK',
    name: 'Cook Islands',
    flag: '🇨🇰',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    flag: '🇬🇧',
  },
  {
    code: 'ZM',
    name: 'Zambia',
    flag: '🇿🇲',
  },
  {
    code: 'FI',
    name: 'Finland',
    flag: '🇫🇮',
  },
  {
    code: 'NE',
    name: 'Niger',
    flag: '🇳🇪',
  },
  {
    code: 'CX',
    name: 'Christmas Island',
    flag: '🇨🇽',
  },
  {
    code: 'TK',
    name: 'Tokelau',
    flag: '🇹🇰',
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
    flag: '🇬🇼',
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
    flag: '🇦🇿',
  },
  {
    code: 'RE',
    name: 'Réunion',
    flag: '🇷🇪',
  },
  {
    code: 'DJ',
    name: 'Djibouti',
    flag: '🇩🇯',
  },
  {
    code: 'KP',
    name: 'North Korea',
    flag: '🇰🇵',
  },
  {
    code: 'MU',
    name: 'Mauritius',
    flag: '🇲🇺',
  },
  {
    code: 'MS',
    name: 'Montserrat',
    flag: '🇲🇸',
  },
  {
    code: 'VI',
    name: 'United States Virgin Islands',
    flag: '🇻🇮',
  },
  {
    code: 'CO',
    name: 'Colombia',
    flag: '🇨🇴',
  },
  {
    code: 'GR',
    name: 'Greece',
    flag: '🇬🇷',
  },
  {
    code: 'HR',
    name: 'Croatia',
    flag: '🇭🇷',
  },
  {
    code: 'MA',
    name: 'Morocco',
    flag: '🇲🇦',
  },
  {
    code: 'DZ',
    name: 'Algeria',
    flag: '🇩🇿',
  },
  {
    code: 'AQ',
    name: 'Antarctica',
    flag: '🇦🇶',
  },
  {
    code: 'NL',
    name: 'Netherlands',
    flag: '🇳🇱',
  },
  {
    code: 'SD',
    name: 'Sudan',
    flag: '🇸🇩',
  },
  {
    code: 'FJ',
    name: 'Fiji',
    flag: '🇫🇯',
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
    flag: '🇱🇮',
  },
  {
    code: 'NP',
    name: 'Nepal',
    flag: '🇳🇵',
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
    flag: '🇵🇷',
  },
  {
    code: 'GE',
    name: 'Georgia',
    flag: '🇬🇪',
  },
  {
    code: 'PK',
    name: 'Pakistan',
    flag: '🇵🇰',
  },
  {
    code: 'MC',
    name: 'Monaco',
    flag: '🇲🇨',
  },
  {
    code: 'BW',
    name: 'Botswana',
    flag: '🇧🇼',
  },
  {
    code: 'LB',
    name: 'Lebanon',
    flag: '🇱🇧',
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
    flag: '🇵🇬',
  },
  {
    code: 'YT',
    name: 'Mayotte',
    flag: '🇾🇹',
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
    flag: '🇩🇴',
  },
  {
    code: 'NF',
    name: 'Norfolk Island',
    flag: '🇳🇫',
  },
  {
    code: 'BV',
    name: 'Bouvet Island',
    flag: '🇧🇻',
  },
  {
    code: 'QA',
    name: 'Qatar',
    flag: '🇶🇦',
  },
  {
    code: 'MG',
    name: 'Madagascar',
    flag: '🇲🇬',
  },
  {
    code: 'IN',
    name: 'India',
    flag: '🇮🇳',
  },
  {
    code: 'SY',
    name: 'Syria',
    flag: '🇸🇾',
  },
  {
    code: 'ME',
    name: 'Montenegro',
    flag: '🇲🇪',
  },
  {
    code: 'SZ',
    name: 'Eswatini',
    flag: '🇸🇿',
  },
  {
    code: 'PY',
    name: 'Paraguay',
    flag: '🇵🇾',
  },
  {
    code: 'SV',
    name: 'El Salvador',
    flag: '🇸🇻',
  },
  {
    code: 'UA',
    name: 'Ukraine',
    flag: '🇺🇦',
  },
  {
    code: 'IM',
    name: 'Isle of Man',
    flag: '🇮🇲',
  },
  {
    code: 'NA',
    name: 'Namibia',
    flag: '🇳🇦',
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    flag: '🇦🇪',
  },
  {
    code: 'BG',
    name: 'Bulgaria',
    flag: '🇧🇬',
  },
  {
    code: 'GL',
    name: 'Greenland',
    flag: '🇬🇱',
  },
  {
    code: 'DE',
    name: 'Germany',
    flag: '🇩🇪',
  },
  {
    code: 'KH',
    name: 'Cambodia',
    flag: '🇰🇭',
  },
  {
    code: 'IQ',
    name: 'Iraq',
    flag: '🇮🇶',
  },
  {
    code: 'TF',
    name: 'French Southern and Antarctic Lands',
    flag: '🇹🇫',
  },
  {
    code: 'SE',
    name: 'Sweden',
    flag: '🇸🇪',
  },
  {
    code: 'CU',
    name: 'Cuba',
    flag: '🇨🇺',
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
    flag: '🇰🇬',
  },
  {
    code: 'RU',
    name: 'Russia',
    flag: '🇷🇺',
  },
  {
    code: 'MY',
    name: 'Malaysia',
    flag: '🇲🇾',
  },
  {
    code: 'ST',
    name: 'São Tomé and Príncipe',
    flag: '🇸🇹',
  },
  {
    code: 'CY',
    name: 'Cyprus',
    flag: '🇨🇾',
  },
  {
    code: 'CA',
    name: 'Canada',
    flag: '🇨🇦',
  },
  {
    code: 'MW',
    name: 'Malawi',
    flag: '🇲🇼',
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
    flag: '🇸🇦',
  },
  {
    code: 'BA',
    name: 'Bosnia and Herzegovina',
    flag: '🇧🇦',
  },
  {
    code: 'ET',
    name: 'Ethiopia',
    flag: '🇪🇹',
  },
  {
    code: 'ES',
    name: 'Spain',
    flag: '🇪🇸',
  },
  {
    code: 'SI',
    name: 'Slovenia',
    flag: '🇸🇮',
  },
  {
    code: 'OM',
    name: 'Oman',
    flag: '🇴🇲',
  },
  {
    code: 'PM',
    name: 'Saint Pierre and Miquelon',
    flag: '🇵🇲',
  },
  {
    code: 'MO',
    name: 'Macau',
    flag: '🇲🇴',
  },
  {
    code: 'SM',
    name: 'San Marino',
    flag: '🇸🇲',
  },
  {
    code: 'LS',
    name: 'Lesotho',
    flag: '🇱🇸',
  },
  {
    code: 'MH',
    name: 'Marshall Islands',
    flag: '🇲🇭',
  },
  {
    code: 'SX',
    name: 'Sint Maarten',
    flag: '🇸🇽',
  },
  {
    code: 'IS',
    name: 'Iceland',
    flag: '🇮🇸',
  },
  {
    code: 'LU',
    name: 'Luxembourg',
    flag: '🇱🇺',
  },
  {
    code: 'AR',
    name: 'Argentina',
    flag: '🇦🇷',
  },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
    flag: '🇹🇨',
  },
  {
    code: 'NR',
    name: 'Nauru',
    flag: '🇳🇷',
  },
  {
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
    flag: '🇨🇨',
  },
  {
    code: 'EH',
    name: 'Western Sahara',
    flag: '🇪🇭',
  },
  {
    code: 'DM',
    name: 'Dominica',
    flag: '🇩🇲',
  },
  {
    code: 'CR',
    name: 'Costa Rica',
    flag: '🇨🇷',
  },
  {
    code: 'AU',
    name: 'Australia',
    flag: '🇦🇺',
  },
  {
    code: 'TH',
    name: 'Thailand',
    flag: '🇹🇭',
  },
  {
    code: 'HT',
    name: 'Haiti',
    flag: '🇭🇹',
  },
  {
    code: 'TV',
    name: 'Tuvalu',
    flag: '🇹🇻',
  },
  {
    code: 'HN',
    name: 'Honduras',
    flag: '🇭🇳',
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
    flag: '🇬🇶',
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
    flag: '🇱🇨',
  },
  {
    code: 'PF',
    name: 'French Polynesia',
    flag: '🇵🇫',
  },
  {
    code: 'BY',
    name: 'Belarus',
    flag: '🇧🇾',
  },
  {
    code: 'LV',
    name: 'Latvia',
    flag: '🇱🇻',
  },
  {
    code: 'PW',
    name: 'Palau',
    flag: '🇵🇼',
  },
  {
    code: 'GP',
    name: 'Guadeloupe',
    flag: '🇬🇵',
  },
  {
    code: 'PH',
    name: 'Philippines',
    flag: '🇵🇭',
  },
  {
    code: 'GI',
    name: 'Gibraltar',
    flag: '🇬🇮',
  },
  {
    code: 'DK',
    name: 'Denmark',
    flag: '🇩🇰',
  },
  {
    code: 'CM',
    name: 'Cameroon',
    flag: '🇨🇲',
  },
  {
    code: 'GN',
    name: 'Guinea',
    flag: '🇬🇳',
  },
  {
    code: 'BH',
    name: 'Bahrain',
    flag: '🇧🇭',
  },
  {
    code: 'SR',
    name: 'Suriname',
    flag: '🇸🇷',
  },
  {
    code: 'CD',
    name: 'DR Congo',
    flag: '🇨🇩',
  },
  {
    code: 'SO',
    name: 'Somalia',
    flag: '🇸🇴',
  },
  {
    code: 'CZ',
    name: 'Czechia',
    flag: '🇨🇿',
  },
  {
    code: 'NC',
    name: 'New Caledonia',
    flag: '🇳🇨',
  },
  {
    code: 'VU',
    name: 'Vanuatu',
    flag: '🇻🇺',
  },
  {
    code: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    flag: '🇸🇭',
  },
  {
    code: 'TG',
    name: 'Togo',
    flag: '🇹🇬',
  },
  {
    code: 'VG',
    name: 'British Virgin Islands',
    flag: '🇻🇬',
  },
  {
    code: 'KE',
    name: 'Kenya',
    flag: '🇰🇪',
  },
  {
    code: 'NU',
    name: 'Niue',
    flag: '🇳🇺',
  },
  {
    code: 'HM',
    name: 'Heard Island and McDonald Islands',
    flag: '🇭🇲',
  },
  {
    code: 'RW',
    name: 'Rwanda',
    flag: '🇷🇼',
  },
  {
    code: 'EE',
    name: 'Estonia',
    flag: '🇪🇪',
  },
  {
    code: 'RO',
    name: 'Romania',
    flag: '🇷🇴',
  },
  {
    code: 'TT',
    name: 'Trinidad and Tobago',
    flag: '🇹🇹',
  },
  {
    code: 'GY',
    name: 'Guyana',
    flag: '🇬🇾',
  },
  {
    code: 'TL',
    name: 'Timor-Leste',
    flag: '🇹🇱',
  },
  {
    code: 'VN',
    name: 'Vietnam',
    flag: '🇻🇳',
  },
  {
    code: 'UY',
    name: 'Uruguay',
    flag: '🇺🇾',
  },
  {
    code: 'VA',
    name: 'Vatican City',
    flag: '🇻🇦',
  },
  {
    code: 'HK',
    name: 'Hong Kong',
    flag: '🇭🇰',
  },
  {
    code: 'AT',
    name: 'Austria',
    flag: '🇦🇹',
  },
  {
    code: 'AG',
    name: 'Antigua and Barbuda',
    flag: '🇦🇬',
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
    flag: '🇹🇲',
  },
  {
    code: 'MZ',
    name: 'Mozambique',
    flag: '🇲🇿',
  },
  {
    code: 'PA',
    name: 'Panama',
    flag: '🇵🇦',
  },
  {
    code: 'FM',
    name: 'Micronesia',
    flag: '🇫🇲',
  },
  {
    code: 'IE',
    name: 'Ireland',
    flag: '🇮🇪',
  },
  {
    code: 'CW',
    name: 'Curaçao',
    flag: '🇨🇼',
  },
  {
    code: 'GF',
    name: 'French Guiana',
    flag: '🇬🇫',
  },
  {
    code: 'NO',
    name: 'Norway',
    flag: '🇳🇴',
  },
  {
    code: 'AX',
    name: 'Åland Islands',
    flag: '🇦🇽',
  },
  {
    code: 'CF',
    name: 'Central African Republic',
    flag: '🇨🇫',
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
    flag: '🇧🇫',
  },
  {
    code: 'ER',
    name: 'Eritrea',
    flag: '🇪🇷',
  },
  {
    code: 'TZ',
    name: 'Tanzania',
    flag: '🇹🇿',
  },
  {
    code: 'KR',
    name: 'South Korea',
    flag: '🇰🇷',
  },
  {
    code: 'JO',
    name: 'Jordan',
    flag: '🇯🇴',
  },
  {
    code: 'MR',
    name: 'Mauritania',
    flag: '🇲🇷',
  },
  {
    code: 'LT',
    name: 'Lithuania',
    flag: '🇱🇹',
  },
  {
    code: 'UM',
    name: 'United States Minor Outlying Islands',
    flag: '🇺🇲',
  },
  {
    code: 'SK',
    name: 'Slovakia',
    flag: '🇸🇰',
  },
  {
    code: 'AO',
    name: 'Angola',
    flag: '🇦🇴',
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
    flag: '🇰🇿',
  },
  {
    code: 'MD',
    name: 'Moldova',
    flag: '🇲🇩',
  },
  {
    code: 'ML',
    name: 'Mali',
    flag: '🇲🇱',
  },
  {
    code: 'FK',
    name: 'Falkland Islands',
    flag: '🇫🇰',
  },
  {
    code: 'AM',
    name: 'Armenia',
    flag: '🇦🇲',
  },
  {
    code: 'WS',
    name: 'Samoa',
    flag: '🇼🇸',
  },
  {
    code: 'JE',
    name: 'Jersey',
    flag: '🇯🇪',
  },
  {
    code: 'JP',
    name: 'Japan',
    flag: '🇯🇵',
  },
  {
    code: 'BO',
    name: 'Bolivia',
    flag: '🇧🇴',
  },
  {
    code: 'CL',
    name: 'Chile',
    flag: '🇨🇱',
  },
  {
    code: 'US',
    name: 'United States',
    flag: '🇺🇸',
  },
  {
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
    flag: '🇻🇨',
  },
  {
    code: 'BM',
    name: 'Bermuda',
    flag: '🇧🇲',
  },
  {
    code: 'SC',
    name: 'Seychelles',
    flag: '🇸🇨',
  },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
    flag: '🇮🇴',
  },
  {
    code: 'GT',
    name: 'Guatemala',
    flag: '🇬🇹',
  },
  {
    code: 'EC',
    name: 'Ecuador',
    flag: '🇪🇨',
  },
  {
    code: 'MQ',
    name: 'Martinique',
    flag: '🇲🇶',
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
    flag: '🇹🇯',
  },
  {
    code: 'MT',
    name: 'Malta',
    flag: '🇲🇹',
  },
  {
    code: 'GM',
    name: 'Gambia',
    flag: '🇬🇲',
  },
  {
    code: 'NG',
    name: 'Nigeria',
    flag: '🇳🇬',
  },
  {
    code: 'BS',
    name: 'Bahamas',
    flag: '🇧🇸',
  },
  {
    code: 'XK',
    name: 'Kosovo',
    flag: '🇽🇰',
  },
  {
    code: 'KW',
    name: 'Kuwait',
    flag: '🇰🇼',
  },
  {
    code: 'MV',
    name: 'Maldives',
    flag: '🇲🇻',
  },
  {
    code: 'SS',
    name: 'South Sudan',
    flag: '🇸🇸',
  },
  {
    code: 'IR',
    name: 'Iran',
    flag: '🇮🇷',
  },
  {
    code: 'AL',
    name: 'Albania',
    flag: '🇦🇱',
  },
  {
    code: 'BR',
    name: 'Brazil',
    flag: '🇧🇷',
  },
  {
    code: 'RS',
    name: 'Serbia',
    flag: '🇷🇸',
  },
  {
    code: 'BZ',
    name: 'Belize',
    flag: '🇧🇿',
  },
  {
    code: 'MM',
    name: 'Myanmar',
    flag: '🇲🇲',
  },
  {
    code: 'BT',
    name: 'Bhutan',
    flag: '🇧🇹',
  },
  {
    code: 'VE',
    name: 'Venezuela',
    flag: '🇻🇪',
  },
  {
    code: 'LR',
    name: 'Liberia',
    flag: '🇱🇷',
  },
  {
    code: 'JM',
    name: 'Jamaica',
    flag: '🇯🇲',
  },
  {
    code: 'PL',
    name: 'Poland',
    flag: '🇵🇱',
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
    flag: '🇰🇾',
  },
  {
    code: 'BN',
    name: 'Brunei',
    flag: '🇧🇳',
  },
  {
    code: 'KM',
    name: 'Comoros',
    flag: '🇰🇲',
  },
  {
    code: 'GU',
    name: 'Guam',
    flag: '🇬🇺',
  },
  {
    code: 'TO',
    name: 'Tonga',
    flag: '🇹🇴',
  },
  {
    code: 'KI',
    name: 'Kiribati',
    flag: '🇰🇮',
  },
  {
    code: 'GH',
    name: 'Ghana',
    flag: '🇬🇭',
  },
  {
    code: 'TD',
    name: 'Chad',
    flag: '🇹🇩',
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
    flag: '🇿🇼',
  },
  {
    code: 'MF',
    name: 'Saint Martin',
    flag: '🇲🇫',
  },
  {
    code: 'MN',
    name: 'Mongolia',
    flag: '🇲🇳',
  },
  {
    code: 'PT',
    name: 'Portugal',
    flag: '🇵🇹',
  },
  {
    code: 'AS',
    name: 'American Samoa',
    flag: '🇦🇸',
  },
  {
    code: 'CG',
    name: 'Republic of the Congo',
    flag: '🇨🇬',
  },
  {
    code: 'BE',
    name: 'Belgium',
    flag: '🇧🇪',
  },
  {
    code: 'IL',
    name: 'Israel',
    flag: '🇮🇱',
  },
  {
    code: 'NZ',
    name: 'New Zealand',
    flag: '🇳🇿',
  },
  {
    code: 'NI',
    name: 'Nicaragua',
    flag: '🇳🇮',
  },
  {
    code: 'AI',
    name: 'Anguilla',
    flag: '🇦🇮',
  },
];

export const ALL_CURRENCIES: {
  [code: string]: {
    name: string;
    symbol: string;
    flag: string;
  };
} = {
  SHP: {
    name: 'Saint Helena pound',
    symbol: '£',
    flag: '🇸🇭',
  },
  XCD: {
    name: 'Eastern Caribbean dollar',
    symbol: '$',
    flag: '🇦🇮',
  },
  CHF: {
    name: 'Swiss franc',
    symbol: 'Fr.',
    flag: '🇨🇭',
  },
  SLL: {
    name: 'Sierra Leonean leone',
    symbol: 'Le',
    flag: '🇸🇱',
  },
  HUF: {
    name: 'Hungarian forint',
    symbol: 'Ft',
    flag: '🇭🇺',
  },
  TWD: {
    name: 'New Taiwan dollar',
    symbol: '$',
    flag: '🇹🇼',
  },
  XPF: {
    name: 'CFP franc',
    symbol: '₣',
    flag: '🇵🇫',
  },
  BBD: {
    name: 'Barbadian dollar',
    symbol: '$',
    flag: '🇧🇧',
  },
  NZD: {
    name: 'New Zealand dollar',
    symbol: '$',
    flag: '🇳🇿',
  },
  XOF: {
    name: 'West African CFA franc',
    symbol: 'Fr',
    flag: '🇧🇯',
  },
  TND: {
    name: 'Tunisian dinar',
    symbol: 'د.ت',
    flag: '🇹🇳',
  },
  EUR: {
    name: 'Euro',
    symbol: '€',
    flag: '🇪🇺',
  },
  IDR: {
    name: 'Indonesian rupiah',
    symbol: 'Rp',
    flag: '🇮🇩',
  },
  CVE: {
    name: 'Cape Verdean escudo',
    symbol: 'Esc',
    flag: '🇨🇻',
  },
  LAK: {
    name: 'Lao kip',
    symbol: '₭',
    flag: '🇱🇦',
  },
  USD: {
    name: 'United States dollar',
    symbol: '$',
    flag: '🇺🇸',
  },
  UGX: {
    name: 'Ugandan shilling',
    symbol: 'Sh',
    flag: '🇺🇬',
  },
  BIF: {
    name: 'Burundian franc',
    symbol: 'Fr',
    flag: '🇧🇮',
  },
  ZAR: {
    name: 'South African rand',
    symbol: 'R',
    flag: '🇿🇦',
  },
  LYD: {
    name: 'Libyan dinar',
    symbol: 'ل.د',
    flag: '🇱🇾',
  },
  MXN: {
    name: 'Mexican peso',
    symbol: '$',
    flag: '🇲🇽',
  },
  XAF: {
    name: 'Central African CFA franc',
    symbol: 'Fr',
    flag: '🇨🇲',
  },
  MKD: {
    name: 'denar',
    symbol: 'den',
    flag: '🇲🇰',
  },
  CNY: {
    name: 'Chinese yuan',
    symbol: '¥',
    flag: '🇨🇳',
  },
  YER: {
    name: 'Yemeni rial',
    symbol: '﷼',
    flag: '🇾🇪',
  },
  GBP: {
    name: 'British pound',
    symbol: '£',
    flag: '🇬🇧',
  },
  GBX: {
    name: 'British penny sterling',
    symbol: '£',
    flag: '🇬🇧',
  },
  GGP: {
    name: 'Guernsey pound',
    symbol: '£',
    flag: '',
  },
  SBD: {
    name: 'Solomon Islands dollar',
    symbol: '$',
    flag: '🇸🇧',
  },
  NOK: {
    name: 'krone',
    symbol: 'kr',
    flag: '🇳🇴',
  },
  DKK: {
    name: 'Danish krone',
    symbol: 'kr',
    flag: '🇩🇰',
  },
  FOK: {
    name: 'Faroese króna',
    symbol: 'kr',
    flag: '',
  },
  UZS: {
    name: 'Uzbekistani soʻm',
    symbol: "so'm",
    flag: '🇺🇿',
  },
  EGP: {
    name: 'Egyptian pound',
    symbol: '£',
    flag: '🇪🇬',
  },
  LKR: {
    name: 'Sri Lankan rupee',
    symbol: 'Rs  රු',
    flag: '🇱🇰',
  },
  ILS: {
    name: 'Israeli new shekel',
    symbol: '₪',
    flag: '🇮🇱',
  },
  JOD: {
    name: 'Jordanian dinar',
    symbol: 'JD',
    flag: '🇯🇴',
  },
  BDT: {
    name: 'Bangladeshi taka',
    symbol: '৳',
    flag: '🇧🇩',
  },
  PEN: {
    name: 'Peruvian sol',
    symbol: 'S/ ',
    flag: '🇵🇪',
  },
  SGD: {
    name: 'Singapore dollar',
    symbol: '$',
    flag: '🇸🇬',
  },
  TRY: {
    name: 'Turkish lira',
    symbol: '₺',
    flag: '🇹🇷',
  },
  AFN: {
    name: 'Afghan afghani',
    symbol: '؋',
    flag: '🇦🇫',
  },
  AWG: {
    name: 'Aruban florin',
    symbol: 'ƒ',
    flag: '🇦🇼',
  },
  CKD: {
    name: 'Cook Islands dollar',
    symbol: '$',
    flag: '',
  },
  ZMW: {
    name: 'Zambian kwacha',
    symbol: 'ZK',
    flag: '🇿🇲',
  },
  AUD: {
    name: 'Australian dollar',
    symbol: '$',
    flag: '🇦🇺',
  },
  AZN: {
    name: 'Azerbaijani manat',
    symbol: '₼',
    flag: '🇦🇿',
  },
  DJF: {
    name: 'Djiboutian franc',
    symbol: 'Fr',
    flag: '🇩🇯',
  },
  KPW: {
    name: 'North Korean won',
    symbol: '₩',
    flag: '🇰🇵',
  },
  MUR: {
    name: 'Mauritian rupee',
    symbol: '₨',
    flag: '🇲🇺',
  },
  COP: {
    name: 'Colombian peso',
    symbol: '$',
    flag: '🇨🇴',
  },
  MAD: {
    name: 'Moroccan dirham',
    symbol: 'د.م.',
    flag: '🇲🇦',
  },
  DZD: {
    name: 'Algerian dinar',
    symbol: 'د.ج',
    flag: '🇩🇿',
  },
  SDG: {
    name: 'Sudanese pound',
    symbol: 'ج.س',
    flag: '🇸🇩',
  },
  FJD: {
    name: 'Fijian dollar',
    symbol: '$',
    flag: '🇫🇯',
  },
  NPR: {
    name: 'Nepalese rupee',
    symbol: '₨',
    flag: '🇳🇵',
  },
  GEL: {
    name: 'lari',
    symbol: '₾',
    flag: '🇬🇪',
  },
  PKR: {
    name: 'Pakistani rupee',
    symbol: '₨',
    flag: '🇵🇰',
  },
  BWP: {
    name: 'Botswana pula',
    symbol: 'P',
    flag: '🇧🇼',
  },
  LBP: {
    name: 'Lebanese pound',
    symbol: 'ل.ل',
    flag: '🇱🇧',
  },
  PGK: {
    name: 'Papua New Guinean kina',
    symbol: 'K',
    flag: '🇵🇬',
  },
  DOP: {
    name: 'Dominican peso',
    symbol: '$',
    flag: '🇩🇴',
  },
  QAR: {
    name: 'Qatari riyal',
    symbol: 'ر.ق',
    flag: '🇶🇦',
  },
  MGA: {
    name: 'Malagasy ariary',
    symbol: 'Ar',
    flag: '🇲🇬',
  },
  INR: {
    name: 'Indian rupee',
    symbol: '₹',
    flag: '🇮🇳',
  },
  SYP: {
    name: 'Syrian pound',
    symbol: '£',
    flag: '🇸🇾',
  },
  SZL: {
    name: 'Swazi lilangeni',
    symbol: 'L',
    flag: '🇸🇿',
  },
  PYG: {
    name: 'Paraguayan guaraní',
    symbol: '₲',
    flag: '🇵🇾',
  },
  UAH: {
    name: 'Ukrainian hryvnia',
    symbol: '₴',
    flag: '🇺🇦',
  },
  IMP: {
    name: 'Manx pound',
    symbol: '£',
    flag: '',
  },
  NAD: {
    name: 'Namibian dollar',
    symbol: '$',
    flag: '🇳🇦',
  },
  AED: {
    name: 'United Arab Emirates dirham',
    symbol: 'د.إ',
    flag: '🇦🇪',
  },
  BGN: {
    name: 'Bulgarian lev',
    symbol: 'лв',
    flag: '🇧🇬',
  },
  KHR: {
    name: 'Cambodian riel',
    symbol: '៛',
    flag: '🇰🇭',
  },
  IQD: {
    name: 'Iraqi dinar',
    symbol: 'ع.د',
    flag: '🇮🇶',
  },
  SEK: {
    name: 'Swedish krona',
    symbol: 'kr',
    flag: '🇸🇪',
  },
  CUC: {
    name: 'Cuban convertible peso',
    symbol: '$',
    flag: '🇨🇺',
  },
  CUP: {
    name: 'Cuban peso',
    symbol: '$',
    flag: '🇨🇺',
  },
  KGS: {
    name: 'Kyrgyzstani som',
    symbol: 'с',
    flag: '🇰🇬',
  },
  RUB: {
    name: 'Russian ruble',
    symbol: '₽',
    flag: '🇷🇺',
  },
  MYR: {
    name: 'Malaysian ringgit',
    symbol: 'RM',
    flag: '🇲🇾',
  },
  STN: {
    name: 'São Tomé and Príncipe dobra',
    symbol: 'Db',
    flag: '',
  },
  CAD: {
    name: 'Canadian dollar',
    symbol: '$',
    flag: '🇨🇦',
  },
  MWK: {
    name: 'Malawian kwacha',
    symbol: 'MK',
    flag: '🇲🇼',
  },
  SAR: {
    name: 'Saudi riyal',
    symbol: 'ر.س',
    flag: '🇸🇦',
  },
  BAM: {
    name: 'Bosnia and Herzegovina convertible mark',
    symbol: 'KM',
    flag: '🇧🇦',
  },
  ETB: {
    name: 'Ethiopian birr',
    symbol: 'Br',
    flag: '🇪🇹',
  },
  OMR: {
    name: 'Omani rial',
    symbol: 'ر.ع.',
    flag: '🇴🇲',
  },
  MOP: {
    name: 'Macanese pataca',
    symbol: 'P',
    flag: '🇲🇴',
  },
  LSL: {
    name: 'Lesotho loti',
    symbol: 'L',
    flag: '🇱🇸',
  },
  ANG: {
    name: 'Netherlands Antillean guilder',
    symbol: 'ƒ',
    flag: '🇳🇱',
  },
  ISK: {
    name: 'Icelandic króna',
    symbol: 'kr',
    flag: '🇮🇸',
  },
  ARS: {
    name: 'Argentine peso',
    symbol: '$',
    flag: '🇦🇷',
  },
  MRU: {
    name: 'Mauritanian ouguiya',
    symbol: 'UM',
    flag: '',
  },
  CRC: {
    name: 'Costa Rican colón',
    symbol: '₡',
    flag: '🇨🇷',
  },
  THB: {
    name: 'Thai baht',
    symbol: '฿',
    flag: '🇹🇭',
  },
  HTG: {
    name: 'Haitian gourde',
    symbol: 'G',
    flag: '🇭🇹',
  },
  TVD: {
    name: 'Tuvaluan dollar',
    symbol: '$',
    flag: '',
  },
  HNL: {
    name: 'Honduran lempira',
    symbol: 'L',
    flag: '🇭🇳',
  },
  BYN: {
    name: 'Belarusian ruble',
    symbol: 'Br',
    flag: '🇧🇾',
  },
  PHP: {
    name: 'Philippine peso',
    symbol: '₱',
    flag: '🇵🇭',
  },
  GIP: {
    name: 'Gibraltar pound',
    symbol: '£',
    flag: '🇬🇮',
  },
  GNF: {
    name: 'Guinean franc',
    symbol: 'Fr',
    flag: '🇬🇳',
  },
  BHD: {
    name: 'Bahraini dinar',
    symbol: '.د.ب',
    flag: '🇧🇭',
  },
  SRD: {
    name: 'Surinamese dollar',
    symbol: '$',
    flag: '🇸🇷',
  },
  CDF: {
    name: 'Congolese franc',
    symbol: 'FC',
    flag: '🇨🇩',
  },
  SOS: {
    name: 'Somali shilling',
    symbol: 'Sh',
    flag: '🇸🇴',
  },
  CZK: {
    name: 'Czech koruna',
    symbol: 'Kč',
    flag: '🇨🇿',
  },
  VUV: {
    name: 'Vanuatu vatu',
    symbol: 'Vt',
    flag: '🇻🇺',
  },
  KES: {
    name: 'Kenyan shilling',
    symbol: 'Sh',
    flag: '🇰🇪',
  },
  RWF: {
    name: 'Rwandan franc',
    symbol: 'Fr',
    flag: '🇷🇼',
  },
  RON: {
    name: 'Romanian leu',
    symbol: 'lei',
    flag: '🇷🇴',
  },
  TTD: {
    name: 'Trinidad and Tobago dollar',
    symbol: '$',
    flag: '🇹🇹',
  },
  GYD: {
    name: 'Guyanese dollar',
    symbol: '$',
    flag: '🇬🇾',
  },
  VND: {
    name: 'Vietnamese đồng',
    symbol: '₫',
    flag: '🇻🇳',
  },
  UYU: {
    name: 'Uruguayan peso',
    symbol: '$',
    flag: '🇺🇾',
  },
  HKD: {
    name: 'Hong Kong dollar',
    symbol: '$',
    flag: '🇭🇰',
  },
  TMT: {
    name: 'Turkmenistan manat',
    symbol: 'm',
    flag: '🇹🇲',
  },
  MZN: {
    name: 'Mozambican metical',
    symbol: 'MT',
    flag: '🇲🇿',
  },
  PAB: {
    name: 'Panamanian balboa',
    symbol: 'B/.',
    flag: '🇵🇦',
  },
  ERN: {
    name: 'Eritrean nakfa',
    symbol: 'Nfk',
    flag: '🇪🇷',
  },
  TZS: {
    name: 'Tanzanian shilling',
    symbol: 'Sh',
    flag: '🇹🇿',
  },
  KRW: {
    name: 'South Korean won',
    symbol: '₩',
    flag: '🇰🇷',
  },
  AOA: {
    name: 'Angolan kwanza',
    symbol: 'Kz',
    flag: '🇦🇴',
  },
  KZT: {
    name: 'Kazakhstani tenge',
    symbol: '₸',
    flag: '🇰🇿',
  },
  MDL: {
    name: 'Moldovan leu',
    symbol: 'L',
    flag: '🇲🇩',
  },
  FKP: {
    name: 'Falkland Islands pound',
    symbol: '£',
    flag: '🇫🇰',
  },
  AMD: {
    name: 'Armenian dram',
    symbol: '֏',
    flag: '🇦🇲',
  },
  WST: {
    name: 'Samoan tālā',
    symbol: 'T',
    flag: '🇼🇸',
  },
  JEP: {
    name: 'Jersey pound',
    symbol: '£',
    flag: '',
  },
  JPY: {
    name: 'Japanese yen',
    symbol: '¥',
    flag: '🇯🇵',
  },
  BOB: {
    name: 'Bolivian boliviano',
    symbol: 'Bs.',
    flag: '🇧🇴',
  },
  CLP: {
    name: 'Chilean peso',
    symbol: '$',
    flag: '🇨🇱',
  },
  BMD: {
    name: 'Bermudian dollar',
    symbol: '$',
    flag: '🇧🇲',
  },
  SCR: {
    name: 'Seychellois rupee',
    symbol: '₨',
    flag: '🇸🇨',
  },
  GTQ: {
    name: 'Guatemalan quetzal',
    symbol: 'Q',
    flag: '🇬🇹',
  },
  TJS: {
    name: 'Tajikistani somoni',
    symbol: 'ЅМ',
    flag: '🇹🇯',
  },
  GMD: {
    name: 'dalasi',
    symbol: 'D',
    flag: '🇬🇲',
  },
  NGN: {
    name: 'Nigerian naira',
    symbol: '₦',
    flag: '🇳🇬',
  },
  BSD: {
    name: 'Bahamian dollar',
    symbol: '$',
    flag: '🇧🇸',
  },
  KWD: {
    name: 'Kuwaiti dinar',
    symbol: 'د.ك',
    flag: '🇰🇼',
  },
  MVR: {
    name: 'Maldivian rufiyaa',
    symbol: '.ރ',
    flag: '🇲🇻',
  },
  SSP: {
    name: 'South Sudanese pound',
    symbol: '£',
    flag: '',
  },
  IRR: {
    name: 'Iranian rial',
    symbol: '﷼',
    flag: '🇮🇷',
  },
  ALL: {
    name: 'Albanian lek',
    symbol: 'L',
    flag: '🇦🇱',
  },
  BRL: {
    name: 'Brazilian real',
    symbol: 'R$',
    flag: '🇧🇷',
  },
  RSD: {
    name: 'Serbian dinar',
    symbol: 'дин.',
    flag: '🇷🇸',
  },
  BZD: {
    name: 'Belize dollar',
    symbol: '$',
    flag: '🇧🇿',
  },
  MMK: {
    name: 'Burmese kyat',
    symbol: 'Ks',
    flag: '🇲🇲',
  },
  BTN: {
    name: 'Bhutanese ngultrum',
    symbol: 'Nu.',
    flag: '🇧🇹',
  },
  VES: {
    name: 'Venezuelan bolívar soberano',
    symbol: 'Bs.S.',
    flag: '🇻🇪',
  },
  LRD: {
    name: 'Liberian dollar',
    symbol: '$',
    flag: '🇱🇷',
  },
  JMD: {
    name: 'Jamaican dollar',
    symbol: '$',
    flag: '🇯🇲',
  },
  PLN: {
    name: 'Polish złoty',
    symbol: 'zł',
    flag: '🇵🇱',
  },
  KYD: {
    name: 'Cayman Islands dollar',
    symbol: '$',
    flag: '🇰🇾',
  },
  BND: {
    name: 'Brunei dollar',
    symbol: '$',
    flag: '🇧🇳',
  },
  KMF: {
    name: 'Comorian franc',
    symbol: 'Fr',
    flag: '🇰🇲',
  },
  TOP: {
    name: 'Tongan paʻanga',
    symbol: 'T$',
    flag: '🇹🇴',
  },
  KID: {
    name: 'Kiribati dollar',
    symbol: '$',
    flag: '',
  },
  GHS: {
    name: 'Ghanaian cedi',
    symbol: '₵',
    flag: '🇬🇭',
  },
  ZWL: {
    name: 'Zimbabwean dollar',
    symbol: '$',
    flag: '🇿🇼',
  },
  MNT: {
    name: 'Mongolian tögrög',
    symbol: '₮',
    flag: '🇲🇳',
  },
  NIO: {
    name: 'Nicaraguan córdoba',
    symbol: 'C$',
    flag: '🇳🇮',
  },
};
