import type { EventName } from './types';
import { isBrowser } from '../env';

type MessageType = 'track'; // Add more values if needed

type QuoraEventName =
  | 'Generic'
  | 'Purchase'
  | 'GenerateLead'
  | 'CompleteRegistration'
  | 'AddPaymentInfo'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'InitiateCheckout'
  | 'Search';

type QuoraPixelFn = (message: MessageType, event: QuoraEventName) => void;

declare global {
  interface Window {
    qp?: QuoraPixelFn;
  }
}

class QuoraPixel {
  private qp: QuoraPixelFn;

  static getQuoraEventName(eventName: EventName): QuoraEventName | null {
    switch (eventName) {
      case 'login':
        return 'CompleteRegistration';
      default:
        return null;
    }
  }

  constructor() {
    this.qp = (isBrowser() && window.qp) || (() => {});
  }

  public push(message: MessageType, eventName: EventName): void {
    const quoraEventName = QuoraPixel.getQuoraEventName(eventName);
    if (quoraEventName) {
      this.qp(message, quoraEventName);
    }
  }
}

export default new QuoraPixel();
