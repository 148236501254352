import { captureException } from '@sentry/gatsby';
import { isProduction } from './env';

export class Logger {
  static error(error: unknown) {
    try {
      captureException(error);
    } catch (_e) {
      console.error('Error: cannot report the issue...');
    }
    if (!isProduction()) {
      console.error(error);
    }
  }
}

export default Logger;
