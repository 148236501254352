import { useEffect, useState, useCallback } from 'react';
import { useStatePersist as useStatePersistOriginal } from 'use-state-persist';
import { isBrowser } from '../../utils/env';

const useStatePersist = <T>(
  key: string,
  initialValue: T,
): [T, (v: T) => void, boolean] => {
  if (!isBrowser()) {
    return [initialValue, () => {}, false];
  }

  const [isReady, setIsReady] = useState<boolean>(false);
  const [value, setValue] = useStatePersistOriginal<T>(key, initialValue);

  useEffect(() => {
    setIsReady(true);
  }, []);

  const setValueIfReady: typeof setValue = useCallback(
    (val) => {
      if (!isReady) {
        return;
      }

      setValue(val);
    },
    [isReady],
  );

  if (!isReady) {
    return [initialValue, setValueIfReady, false];
  }

  return [value, setValueIfReady, true];
};

export default useStatePersist;
