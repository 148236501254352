import { type FC, useEffect } from 'react';
import { FlexboxGrid, Col } from 'rsuite';
import type { Broker } from '@investorean/types';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { BrokerSelect, BrokerPanelContainer, Row } from './styles';
import { useBroker } from '../../context/broker';
import { useFirebase } from '../../context/firebase';
import AssetLookupInput from '../AssetLookupInput';
import SectionContainer from '../../common/SectionContainer';
import { BrokerSelectItem } from './BrokerSelectItem';
import { brokersSelectData } from './constants';
import { env } from '../../utils/env';
import type { BrokerSelectItemName } from './types';
import { FieldContainer } from '../../styles/common';

declare global {
  interface Window {
    feedspace: {
      init: () => void;
      setUserDetails: (params: Record<string, string>) => void;
      dom: {
        renderModal: (
          modalId: string,
          context: null,
          options: { app_page_id: string },
        ) => void;
      };
      appPageId: string;
    };
  }
}

const BrokerPanel: FC = () => {
  const { broker, setBroker, isLoading } = useBroker();
  const { user } = useFirebase();
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (user) {
      window.feedspace?.setUserDetails({
        id: user.uid,
        name: user.name,
        email: user.email,
      });
    }
  }, []);

  return (
    <BrokerPanelContainer>
      <SectionContainer>
        <FlexboxGrid justify="space-between">
          <Row as={Col} colSpan={24} xs={24} sm={11} md={8} lg={6}>
            <FieldContainer>
              <BrokerSelect
                data={brokersSelectData.map(({ label, broker }) => ({
                  label: (
                    <BrokerSelectItem
                      key={`broker-select-item-${broker}`}
                      broker={broker}
                      label={label}
                    />
                  ),
                  value: broker as string,
                }))}
                disabled={isLoading}
                // disabledItemValues={[]}
                onChange={(value) => {
                  switch (value as BrokerSelectItemName) {
                    case 'any-broker':
                      setBroker(null);
                      mixpanel.track('Change broker', { broker: 'any-broker' });
                      break;
                    case 'not-existing': {
                      if (window.feedspace) {
                        window.feedspace.dom.renderModal(
                          env.feedspace.broker,
                          null,
                          { app_page_id: window.feedspace.appPageId },
                        );
                      }
                      setBroker(null);
                      break;
                    }
                    default:
                      setBroker(value as Broker);
                      mixpanel.track('Change broker', { broker: value });
                      break;
                  }
                }}
                size="md"
                name="select-broker"
                searchable={false}
                value={broker}
                cleanable={false}
                placeholder={
                  <BrokerSelectItem
                    broker="any-broker"
                    label={brokersSelectData[0].label}
                  />
                }
              />
            </FieldContainer>
          </Row>
          <Row as={Col} colSpan={24} xs={24} sm={11} md={8} lg={6}>
            <FieldContainer>
              <AssetLookupInput />
            </FieldContainer>
          </Row>
        </FlexboxGrid>
      </SectionContainer>
    </BrokerPanelContainer>
  );
};

export default BrokerPanel;
