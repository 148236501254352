import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/app-check';
import 'firebase/remote-config';
import 'firebase/storage';
import 'rsuite/dist/rsuite.min.css';
import './src/styles/global.css';

export { wrapRootElement } from './src/context/wrapRootElement';
export { wrapPageElement } from './src/context/wrapPageElement';

export const registerServiceWorker = () => true;
