import { createGlobalStyle } from 'styled-components';
import type { Theme } from '../context/theme';

export const colors = {
  yellow: '#f9dc5c',
  red: '#ED254E',
  lightRed: '#F03D2D',
  green: '#008148',

  etoro: '#3fb923',
  naga: '#f80000',
  robinhood: '#00c805',
  cmeg: '#1671b7',
  ib: '#d81222',
};

export interface ThemeColors {
  background: string;
  backgroundTone: string;
  primaryLight: string;
  primaryDark: string;
  secondaryLight: string;
  secondaryDark: string;
}

export const themeColors: {
  [theme in Theme]: ThemeColors;
} = {
  light: {
    background: '#FAFAFF',
    backgroundTone: '#FFFFFF',
    primaryLight: '#459CFF',
    primaryDark: '#00234b',
    secondaryLight: '#624de3',
    secondaryDark: '#3c2e92',
  },
  dark: {
    background: '#232731',
    backgroundTone: '#1a1d24',
    primaryLight: '#459CFF',
    primaryDark: '#00234b',
    secondaryLight: '#624de3',
    secondaryDark: '#3c2e92',
  },
};

export const ThemeColorsLight = createGlobalStyle`
  body.rs-theme-light {
    --rs-primary-500: ${themeColors.light.primaryLight};
    --rs-body: ${themeColors.light.background};

    --i-background-tone: ${themeColors.light.backgroundTone};
    --i-primary-light: ${themeColors.light.primaryLight};
    --i-primary-dark: ${themeColors.light.primaryDark};
    --i-secondary-light: ${themeColors.light.secondaryLight};
    --i-secondary-dark: ${themeColors.light.secondaryDark};
    --i-link-primary: ${themeColors.light.primaryDark};
  }
`;

export const ThemeColorsDark = createGlobalStyle`
  body {

    &.rs-theme-dark {
      --rs-text-primary: var(--rs-gray-200);
      --rs-primary-500: ${themeColors.dark.primaryLight};
      --rs-body: ${themeColors.dark.background};

      --i-background-tone: ${themeColors.dark.backgroundTone};
      --i-primary-light: ${themeColors.dark.primaryLight};
      --i-primary-dark: ${themeColors.dark.primaryDark};
      --i-secondary-light: ${themeColors.dark.secondaryLight};
      --i-secondary-dark: ${themeColors.dark.secondaryDark};
      --i-link-primary: var(--rs-gray-50);
    }
  }
`;
