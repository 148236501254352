import {
  type FC,
  useContext,
  useState,
  useCallback,
  createContext,
  type ReactNode,
} from 'react';
import type { Broker } from '@investorean/types';

import type { BrokerContextProps, BrokerState } from './types';
import useStatePersist from '../../hooks/useStatePersist';

export const BrokerContext = createContext<BrokerContextProps>({
  broker: null,
  setBroker: () => {},
  isLoading: false,
  setIsLoading: () => {},
  isBrokerReady: false,
});

export const BrokerContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [brokerState, setBrokerState, isBrokerReady] =
    useStatePersist<BrokerState>('@broker', {
      broker: null,
    });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setBroker = useCallback(
    (broker: Broker | null) => {
      if (!isBrokerReady) {
        return;
      }
      setBrokerState({ broker });
    },
    [isBrokerReady],
  );

  return (
    <BrokerContext.Provider
      value={{
        broker: brokerState.broker,
        setBroker,
        isLoading,
        setIsLoading,
        isBrokerReady,
      }}
    >
      {children}
    </BrokerContext.Provider>
  );
};

export const useBroker = (): BrokerContextProps =>
  useContext<BrokerContextProps>(BrokerContext);

export * from './constants';
