import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import type { Variant } from './types';

export const Link = styled(GatsbyLink)<{
  $variant: Variant;
  $active: boolean;
}>`
  display: inline-block;
  position: relative;

  ${({ $variant }) => {
    switch ($variant) {
      case 'primary':
      case 'secondary':
      case 'ghost':
        return `
          display: inline-block;
          font-size: 1.2em;
          font-weight: bold;
          width: auto;
          border-radius: 40px;
          height: 40px;
          line-height: 34px;
          padding: 0 25px;
          transition: all .2s ease-in-out;

          &:hover {
            text-decoration: none;
          }
        `;
      default:
        return '';
    }
  }}

  ${(props) =>
    props.$variant === 'primary' &&
    `
    background-color: var(--rs-primary-500);
    border: 2px solid var(--rs-primary-400);
    color: var(--rs-gray-50);

    &:hover,
    &:focus,
    &:active {
      background: var(--rs-primary-400);
      color: var(--rs-gray-50);
      text-decoration: none;
    }
  `};

  ${(props) =>
    props.$variant === 'secondary' &&
    `
    background-color: ${props.$active ? 'var(--rs-primary-500)' : 'var(--i-secondary-light)'};
    color: var(--rs-gray-50);

    &:hover,
    &:focus,
    &:active {
      background: var(--i-secondary-dark);
      color: var(--rs-gray-50);
      text-decoration: none;
    }
  `};

  ${(props) =>
    props.$variant === 'ghost' &&
    `
    background-color: transparent;
    color: ${props.$active ? 'var(--rs-primary-500)' : 'var(--i-link-primary)'};
    border: 2px solid var(--i-link-primary);

    &:hover,
    &:focus,
    &:active {
      background: var(--i-link-primary);
      color: var(--i-background-tone);
      text-decoration: none;
    }
  `};
`;
