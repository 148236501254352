import type { FC } from 'react';
import type { WrapPageElementNodeArgs } from 'gatsby';
import MainLayout from '../components/MainLayout';

export const wrapPageElement: FC<WrapPageElementNodeArgs> = ({
  element,
  props,
}) => {
  return <MainLayout {...props}>{element}</MainLayout>;
};
