import { type FC, useCallback } from 'react';
import { Sun, Moon } from 'feather-icons-react';
import { ThemeSwitchWrapper } from './styled';
import { useTheme } from '../../context/theme';

const ThemeSwitch: FC = () => {
  const { theme, setTheme, isThemeLoaded } = useTheme();

  const switchTheme = useCallback(() => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
  }, [theme, isThemeLoaded]);

  return (
    <ThemeSwitchWrapper title="Switch Light/Dark mode" onClick={switchTheme}>
      {theme === 'dark' ? <Sun /> : <Moon />}
    </ThemeSwitchWrapper>
  );
};

export default ThemeSwitch;
