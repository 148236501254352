import { type FC, useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { useBroker, brokerLabels } from '../../context/broker';
import {
  InputPicker,
  InputContainer,
  SearchIcon,
  Loader,
  AssetLookupMenu,
} from './styles';
import { getAssetDetailsUri } from '../../utils/assets';
import { useAssetsLookup } from '../../hooks/useAssetsLookup';

const AssetLookupInput: FC = () => {
  const { broker } = useBroker();
  const [query, setQuery] = useState<string>('');
  const { lookup, isLoading, results, resultsMenu } = useAssetsLookup();
  const mixpanel = useMixpanel();

  useEffect(() => {
    lookup(query);
  }, [query]);

  return (
    <InputContainer>
      <InputPicker
        data={resultsMenu}
        labelKey="label"
        valueKey="symbol"
        name="asset-lookup"
        onSearch={async (val: string) => {
          setQuery(val);
        }}
        caretAs="i"
        creatable={false}
        placeholder={`Ticker or name${broker ? ` at ${brokerLabels[broker]}...` : '...'}`}
        renderMenu={(menu) => {
          if (isLoading || !query) {
            return null;
          }

          return <AssetLookupMenu>{menu}</AssetLookupMenu>;
        }}
        renderMenuItem={(_event, item) => item.label}
        onSelect={(symbol, event) => {
          if (isLoading || event.create) {
            return;
          }

          mixpanel.track('Asset lookup - select item');
          const asset = results.find((a) => a.symbol === symbol);
          if (asset) {
            navigate(getAssetDetailsUri(symbol, asset.assetType));
          }
        }}
        value=""
        locale={{
          noResultsText: `Nothing found${broker ? ` for ${brokerLabels[broker]}` : ''}...`,
        }}
      />
      {isLoading ? <Loader /> : <SearchIcon />}
    </InputContainer>
  );
};

export default AssetLookupInput;
