import {
  doc,
  getDoc,
  getFirestore,
  collection,
  type CollectionReference,
} from 'firebase/firestore';
import type { UserDbRecord } from '@investorean/types';
import Logger from '../../utils/Logger';

export const getUser = async (uid: string): Promise<UserDbRecord | null> => {
  try {
    const firestore = getFirestore();
    const usersCol = collection(
      firestore,
      'users',
    ) as CollectionReference<UserDbRecord>;
    const userRef = doc(usersCol, uid);
    const userSnapshot = await getDoc(userRef);
    const user = userSnapshot.data();
    return user || null;
  } catch (e) {
    Logger.error(e);
    return null;
  }
};

export const awaitUser = async (uid: string): Promise<UserDbRecord | null> => {
  let currentAttempt = 0;
  return await new Promise((resolve) => {
    const interval = setInterval(async () => {
      const userData = await getUser(uid);
      if (userData?.email) {
        clearInterval(interval);
        resolve(userData);
      }
      if (currentAttempt >= 10) {
        clearInterval(interval);
        resolve(null);
      }
      currentAttempt++;
    }, 1000);
  });
};
