import type { FC } from 'react';
import { useBroker } from '../../context/broker';
import type { BrokerSelectItemProps } from './types';
import { BrokerItem, BrokerItemIcon, BrokerItemLoader } from './styles';

export const BrokerSelectItem: FC<BrokerSelectItemProps> = (props) => {
  const { isLoading } = useBroker();

  return (
    <BrokerItem>
      <BrokerItemIcon $broker={props.broker} />
      <span>{props.label}</span>
      {isLoading ? <BrokerItemLoader /> : null}
    </BrokerItem>
  );
};
