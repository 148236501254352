import type { FC } from 'react';
import type { LinkProps } from './types';
import * as S from './styles';

const Link: FC<LinkProps> = (props) => {
  const { to, variant, children, active, ...restProps } = props;
  return (
    // @ts-ignore
    <S.Link {...restProps} to={to} $variant={variant} $active={!!active}>
      {children}
    </S.Link>
  );
};

export default Link;
