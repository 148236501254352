import { useCallback, useEffect, useState } from 'react';
import type {
  AssetPreviewItem,
  AssetsPreviewRequest,
  AssetsPreviewResponse,
  AssetUnit,
} from '@investorean/types';
import api from '../../utils/apiClient';
import Logger from '../../utils/Logger';
import { useFirebase } from '../../context/firebase';

const useAssetsPreview = (assets: AssetUnit[]) => {
  const { isAppReady } = useFirebase();
  const [items, setItems] = useState<AssetPreviewItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateItems = useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const { data } = await api.post<
        AssetsPreviewRequest,
        AssetsPreviewResponse
      >('/assets/preview', {
        items: assets,
      });
      if (!data?.items?.length) {
        throw new Error('No assets preview found...');
      }
      setItems(data.items);
    } catch (e) {
      Logger.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [assets, isAppReady, isLoading]);

  useEffect(() => {
    if (!isAppReady || !assets?.length) {
      return;
    }
    updateItems();
  }, [assets, isAppReady]);

  return { items, isLoading };
};

export default useAssetsPreview;
