import styled from 'styled-components';

export const ThemeSwitchWrapper = styled.span`
  margin-right: auto;
  padding-top: 5px;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover svg,
  &:focus svg {
    stroke: var(--i-primary-light);
  }
`;
