import { type FC, useState, useEffect } from 'react';
import { Message } from 'rsuite';
import { Statuspage } from 'statuspage.io';
import useStatePersist from '../../hooks/useStatePersist';
import Logger from '../../utils/Logger';
import { EXTERNAL_URLS } from '../../constants/navigation';

const IncidentMessage: FC = () => {
  const [incidents, setIncidents] = useStatePersist<string[]>('incidents', []);
  const [newIncidents, setNewIncidents] = useState<string[]>([]);
  const [status, setStatus] = useState<string>('');

  useEffect(() => {
    async function checkIncidents(): Promise<void> {
      try {
        const statuspage = new Statuspage('investorean');
        const incidentsData = await statuspage.api.incidents.getUnresolved();
        setNewIncidents(
          incidentsData.incidents.reduce((res, { id }) => {
            if (!incidents.includes(id)) {
              res.push(id);
            }
            return res;
          }, [] as string[]),
        );
        const pageStatus = await statuspage.api.getStatus();
        setStatus(pageStatus.status.indicator);
      } catch (e) {
        Logger.error(e);
      }
    }

    checkIncidents();
  }, []);

  if (newIncidents.length) {
    return (
      <Message
        showIcon
        closable
        onClose={() => {
          setIncidents([...incidents, ...newIncidents]);
          setNewIncidents([]);
        }}
        type="warning"
        style={{ zIndex: 999999 }}
      >
        <b>[IMPORTANT]</b>{' '}
        <span>
          There are some incidents in our systems at the moment. You may
          experience some technical issues. We are working on the fix.&nbsp;
        </span>
        <a
          href={EXTERNAL_URLS.status.path}
          title={EXTERNAL_URLS.status.title}
          target="_blank"
          rel="noreferrer"
        >
          Check status
        </a>
      </Message>
    );
  }

  if (status === 'maintenance') {
    return (
      <Message
        showIcon
        closable
        onClose={() => {
          setIncidents([...incidents, ...newIncidents]);
          setNewIncidents([]);
        }}
        type="warning"
        style={{ zIndex: 999999 }}
      >
        <b>[IMPORTANT]</b>{' '}
        <span>
          Our systems are currently under active maintenance, you may experience
          outage. We are apologize for any inconvenience.&nbsp;
        </span>
        <a
          href={EXTERNAL_URLS.status.path}
          title={EXTERNAL_URLS.status.title}
          target="_blank"
          rel="noreferrer"
        >
          Check status
        </a>
      </Message>
    );
  }

  return null;
};

export default IncidentMessage;
