import { useState, useEffect, useCallback } from 'react';

interface ConnectionStatusInfo {
  online: boolean;
}

const useConnectionStatus = (): ConnectionStatusInfo => {
  const [online, setOnline] = useState<boolean>(true);

  const setStatus = useCallback(() => {
    if (!window.navigator) {
      return;
    }

    setOnline(window.navigator.onLine);
  }, []);

  useEffect(() => {
    window.addEventListener('online', setStatus);
    window.addEventListener('offline', setStatus);

    return () => {
      window.removeEventListener('online', setStatus);
      window.removeEventListener('offline', setStatus);
    };
  }, []);

  return { online };
};

export default useConnectionStatus;
