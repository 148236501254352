export const HeaderGradientBackground = `
background-color: var(--i-primary-dark);
background-image: linear-gradient(
  227deg,
  var(--i-primary-dark) -3%,
  var(--i-secondary-dark) 80%
);
`;

export const InvestoreanLink = `
font-weight: 600;
font-family: 'Play', sans-serif;
color: var(--i-link-primary);
text-decoration: none;
background: linear-gradient(
  to right,
  var(--i-link-primary) 20%,
  var(--i-secondary-light) 40%,
  var(--i-secondary-light) 60%,
  var(--i-link-primary) 80%
);
background-size: 200% auto;
color: #000;
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
animation: shine 1s linear 3;
text-transform: uppercase;

&:hover {
  animation-iteration-count: infinite;
}

@keyframes shine {
  to {
    background-position: -200% center;
  }
}
`;
