export const INTERNAL_URIS = {
  homepage: {
    path: '/',
    title: 'Go to the home page',
  },
  login: {
    path: '/login',
    title: 'Go to the login page',
  },
  logout: {
    path: '/logout',
    title: 'Log out',
  },
  dashboard: {
    path: '/dashboard',
    title: 'Go to the dashboard page',
  },
  stock: {
    path: '/stocks',
    title: 'Go to Stocks page',
  },
  stocksScreener: {
    path: '/stocks/screener',
    title: 'Go to the stock screener',
  },
  stockDetails: {
    path: '/stocks/details',
    title: 'Go to the stock details page',
  },
  stocksTopMovers: {
    path: '/stocks/gainers-losers/',
    title: 'Go to stocks top movers',
  },
  stocksDividendsUpcoming: {
    path: '/stocks/dividends/upcoming',
    title: 'Go to the upcoming dividends screener',
  },
  stocksPenny: {
    path: '/stocks/penny',
    title: 'Go to the penny stocks screener',
  },
  stocksWarrenBuffett: {
    path: '/stocks/warren-buffett',
    title: 'Go to the Warren Buffett stock indicator',
  },
  stocksDividendsGrowth: {
    path: '/stocks/dividends/growth',
    title: 'Go to stock dividends growth screener',
  },
  stocksPeterLynch: {
    path: '/stocks/peter-lynch',
    title: 'Go to stocks Peter Lynch indicator',
  },
  index: {
    path: '/indices',
    title: 'Go to stock indices',
  },
  indicesScreener: {
    path: '/indices/screener',
    title: 'Go to stock indices screener',
  },
  indexDetails: {
    path: '/indices/details',
    title: 'Go to the index details page',
  },
  currency: {
    path: '/currencies',
    title: 'Go to currencies',
  },
  currenciesScreener: {
    path: '/currencies/screener',
    title: 'Go to currencies screener',
  },
  currencyDetails: {
    path: '/currencies/details',
    title: 'Go to the currency details page',
  },
  etf: {
    path: '/etfs',
    title: 'Go to ETFs',
  },
  etfsScreener: {
    path: '/etfs/screener',
    title: 'Go to ETFs screener',
  },
  etfDetails: {
    path: '/etfs/details',
    title: 'Go to the ETF details page',
  },
  watchlists: {
    path: '/watchlists',
    title: 'Go to my watchlists',
  },
  portfolios: {
    path: '/portfolios',
    title: 'Go to my portfolios',
  },
  settings: {
    path: '/settings',
    title: 'Go to the settings page',
  },
  pricing: {
    path: '/pricing',
    title: 'Go to the pricing page',
  },
  contact: {
    path: '/contact',
    title: 'Go to the contact page',
  },
  faq: {
    path: '/faq',
    title: 'Go to the FAQ page',
  },
  terms: {
    path: '/terms',
    title: 'Go to the terms and conditions page',
  },
  privacy: {
    path: '/privacy',
    title: 'Go to the privacy policy page',
  },
  learnings: {
    path: '/learnings',
    title: 'Go to the learnings page',
  },
  forgotPassword: {
    path: '/forgot-password',
    title: 'Go to the password recovery page',
  },
  pageNotFound: {
    path: '/404',
    title: 'Page not found',
  },
  payment: {
    path: '/payment',
    title: 'Go to the payment page',
  },
  calendar: {
    path: '/calendar',
    title: 'Go to economic calendar',
  },
};

export const EXTERNAL_URLS = {
  blog: {
    path: '/blog/',
    title: 'Go to the Wallstreetverse',
  },
  status: {
    path: 'https://investorean.statuspage.io/',
    title: 'Go to our status page',
  },
  roadmap: {
    path: 'https://investorean.canny.io/',
    title: 'Go to our roadmap',
  },
  linkedin: {
    path: 'https://www.linkedin.com/company/88909563',
    title: 'Go to our LinkedIn',
  },
  twitter: {
    path: 'https://twitter.com/investorean',
    title: 'Go to our Twitter',
  },
  telegram: {
    path: 'https://t.me/investorean_official',
    title: 'Go to our Telegram',
  },
  reddit: {
    path: 'https://www.reddit.com/r/investorean',
    title: 'Go to our Reddit',
  },
  facebook: {
    path: 'https://facebook.com/investorean',
    title: 'Go to our Facebook',
  },
  instagram: {
    path: 'https://www.instagram.com/investorean',
    title: 'Go to our Instagram',
  },
  tiktok: {
    path: 'https://www.tiktok.com/@investorean',
    title: 'Go to our TikTok',
  },
  scheduleCall: {
    path: 'https://cal.com/investorean',
    title: 'Go to schedule a call page',
  },
  substack: {
    path: 'https://investorean.substack.com',
    title: 'Market Insights Newsletter',
  },
};
