import { type FC, useState, useEffect } from 'react';
import { NavBarDesktop } from './NavBarDesktop';
import { NavBarMobile } from './NavBarMobile';
import { useMediaQuery } from '@react-hook/media-query';

import { useFirebase } from '../../context/firebase';
import { mq } from '../../styles/mediaQueries';
import ThemeSwitch from '../../common/ThemeSwitch';
import { HeaderNavbar, HeaderHomeLink } from './styles';
import { INTERNAL_URIS } from '../../constants/navigation';
import { ReactComponent as InvestoreanLogo } from '../../../static/investorean-logo-square-transparent.svg';

export const Header: FC = () => {
  const { user } = useFirebase();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const isTablet = useMediaQuery(`only screen and (${mq.tablet})`);
  const isDesktop = useMediaQuery(`only screen and (${mq.desktop})`);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <HeaderNavbar>
      <HeaderHomeLink
        to={INTERNAL_URIS.homepage.path}
        title={INTERNAL_URIS.homepage.title}
      >
        <InvestoreanLogo height={26} />
        {(!isLoaded || isDesktop) && <span>Investorean</span>}
      </HeaderHomeLink>
      <ThemeSwitch />
      {isLoaded && isTablet ? (
        <NavBarMobile user={user} />
      ) : (
        <NavBarDesktop user={user} />
      )}
    </HeaderNavbar>
  );
};
