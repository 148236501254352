import { type FC, createContext, useContext, useEffect, useState } from 'react';
import type {
  StaticConfig,
  SubscriptionName,
  UserDbRecord,
} from '@investorean/types';
import { getApp } from 'firebase/app';
import {
  type Functions,
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';
import {
  getAuth,
  type User as FirebaseUser,
  sendPasswordResetEmail,
  updateProfile,
  updatePassword,
} from 'firebase/auth';
import {
  type RemoteConfig,
  type Value,
  isSupported,
  getRemoteConfig,
  getValue,
  fetchAndActivate,
} from 'firebase/remote-config';
import {
  type FirebaseStorage,
  type StorageReference,
  getStorage,
  ref,
  getDownloadURL,
} from 'firebase/storage';
import { Message } from 'rsuite';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { isBrowser, isDevelopment, env, isIE } from '../../utils/env';
import Logger from '../../utils/Logger';
import analytics from '../../utils/analytics';
import type {
  FirebaseContextProps,
  CallFunction,
  FirebaseProviderProps,
} from './types';
import { awaitUser } from './helpers';
import staticConfigEmpty from './staticConfigEmpty';

export const FirebaseContext = createContext<FirebaseContextProps>({
  isAppReady: false,
  appCheck: null,
  subscribedToProduct: () => false,
  resetPassword: () => Promise.resolve(),
  callFunction: () => Promise.resolve({ data: null }),
  user: null,
  setUser: () => {},
  updateProfile,
  updatePassword,
  getRemoteConfigValue: () => null,
  storage: {} as FirebaseStorage,
  staticConfig: staticConfigEmpty,
});

export const FirebaseProvider: FC<FirebaseProviderProps> = ({
  children,
  appCheck,
}) => {
  const auth = getAuth();
  const [user, setUser] = useState<UserDbRecord | null>(null);
  const [staticConfig, setStaticConfig] = useState<StaticConfig | null>(null);
  const [isRemoteConfigSupported, setIsRemoteConfigSupported] =
    useState<boolean>(true);
  const [functions] = useState<Functions>(
    getFunctions(getApp(), env.firebaseRegion),
  );
  const [remoteConfig] = useState<RemoteConfig | null>(
    isBrowser() ? getRemoteConfig() : null,
  );
  const [storage] = useState<FirebaseStorage>(getStorage());
  const [staticConfigRef] = useState<StorageReference>(
    ref(storage, `gs://${env.bucketStatic}/config.json`),
  );
  const [isAppInit, setIsAppInit] = useState<boolean>(false);
  const [isWaitingUserData, setIsWaitingUserData] = useState<boolean>(false);
  const mixpanel = useMixpanel();

  const getRemoteConfigValue = (key: string): Value | null => {
    if (!remoteConfig) {
      return null;
    }
    return getValue(remoteConfig, key);
  };

  // @ts-ignore
  const callFunction: CallFunction = async (name, params) => {
    try {
      return await httpsCallable(functions, name)(params);
    } catch (error) {
      Logger.error(error);
      return Promise.resolve({ data: null });
    }
  };

  async function downloadStaticConfig(): Promise<void> {
    try {
      const url = await getDownloadURL(staticConfigRef);
      const response = await fetch(url);
      const staticConfigData = await response.json();
      setStaticConfig(staticConfigData);
    } catch (e: unknown) {
      Logger.error(e);
    }
  }

  function resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(auth, email);
  }

  useEffect(() => {
    if (isDevelopment()) {
      connectFunctionsEmulator(functions, 'localhost', 5001);
    }

    auth.onAuthStateChanged(
      async (firebaseUser: FirebaseUser | null) => {
        if (!firebaseUser?.uid) {
          setUser(null);
          return;
        }

        setIsWaitingUserData(true);
        mixpanel.identify(firebaseUser.uid);
        mixpanel.track('login');
        analytics.fire('login');
        const userData = await awaitUser(firebaseUser.uid);
        setUser(userData);
        setIsWaitingUserData(false);
      },
      (error) => {
        Logger.error(error);
      },
    );

    const init = async () => {
      if (remoteConfig) {
        remoteConfig.settings.minimumFetchIntervalMillis = 60 * 60 * 12 * 1000; // 12 hours
        fetchAndActivate(remoteConfig);
      }

      await downloadStaticConfig();

      const isRemoteConfigAvailable = await isSupported();
      if (!isRemoteConfigAvailable) {
        setIsRemoteConfigSupported(false);
      }

      setIsAppInit(true);
    };

    init();
  }, []);

  const subscribedToProduct = (product: SubscriptionName) =>
    !!user?.subscriptions?.includes(product);

  if (!isRemoteConfigSupported || isIE()) {
    return (
      <Message showIcon type="error" header="This browser is not supported!">
        Unfortunately, this browser is currently not supported... Please, try to
        use Google Chrome or Mozilla Firefox (not private mode).
      </Message>
    );
  }

  return (
    <FirebaseContext.Provider
      value={{
        isAppReady: isAppInit && !isWaitingUserData,
        appCheck,
        callFunction,
        user,
        setUser,
        updateProfile,
        updatePassword,
        getRemoteConfigValue,
        storage,
        staticConfig: staticConfig || staticConfigEmpty,
        subscribedToProduct,
        resetPassword,
      }}
    >
      {/* biome-ignore lint/complexity/noUselessFragments: @TODO: check if it works otherwise */}
      <>{children}</>
    </FirebaseContext.Provider>
  );
};

export const useFirebase = (): FirebaseContextProps =>
  useContext<FirebaseContextProps>(FirebaseContext);

export * from './types';
