import type { CSSProperties, FC, ReactNode } from 'react';
import * as S from './styles';

interface SectionContainerProps {
  children?: ReactNode;
  style?: CSSProperties;
  maxWidth?: string;
}

const SectionContainer: FC<SectionContainerProps> = ({
  children,
  style,
  maxWidth,
}) => {
  return (
    <S.SectionContainer style={style} $maxWidth={maxWidth}>
      {children}
    </S.SectionContainer>
  );
};

export default SectionContainer;
