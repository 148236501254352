import styled from 'styled-components';
import { colors } from '../../styles/colors';

export const AutosliderContainer = styled.div`
  position: relative;
  width: 100vw;
  overflow: hidden;
  z-index: 1;
  margin: 0;
  padding: 0;
`;

export const AutosliderWrapper = styled.div<{ $speed: number }>`
  overflow: hidden;
  height: 100%;
  white-space: nowrap;
  animation: scrollItems ${({ $speed }) => $speed}ms infinite linear;
  margin: 0;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: fit-content;

  &:hover {
    animation-play-state: paused;
  }

  @keyframes scrollItems {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }
`;

export const AutosliderItem = styled.div`
  display: inline-block;
  margin: 0;
  padding: 10px;

  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
`;

export const AutosliderTicker = styled.span`
  color: var(--i-link-primary);
  font-weight: 600;
`;

export const AutosliderPrice = styled.span`
  color: var(--rs-text-primary);
`;

export const AutosliderPriceChange = styled.span<{ $value: number }>`
  color: ${({ $value }) => ($value < 0 ? colors.red : colors.green)};
`;
