import type { StaticConfig } from '@investorean/types';

const staticConfigEmpty: StaticConfig = {
  stocks: {
    countries: [],
    currencies: [],
    exchanges: [],
    sectors: {},
  },
};

export default staticConfigEmpty;
