import styled from 'styled-components';
import { themeColors } from '../../styles/colors';

export const OfflineContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  background-color: ${themeColors.dark.background};
  background-image: linear-gradient(
    135deg,
    ${themeColors.dark.primaryDark} -3%,
    ${themeColors.dark.secondaryDark} 90%
  );
`;

export const OfflineMessage = styled.p`
  font-size: 1.5em;
  max-width: 80%;
  text-align: center;
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-size: 40px;
  font-family: Play, Arial;
  font-weight: 600;
  text-transform: uppercase;
`;
