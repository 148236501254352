export const isBrowser = (): boolean => typeof window !== 'undefined';

export const isMobile = (): boolean =>
  isBrowser() &&
  window.matchMedia('only screen and (max-width: 760px)').matches;
export const isDesktop = (): boolean =>
  isBrowser() &&
  window.matchMedia('only screen and (min-width: 760px)').matches;

export const isIE = (): boolean =>
  isBrowser() && /MSIE|Trident/.test(window.navigator.userAgent);

export const isDevelopment = (): boolean =>
  !process.env.GATSBY_ENV ||
  process.env.GATSBY_ENV.toLowerCase() === 'development';

export const isProduction = (): boolean =>
  process.env.GATSBY_ENV.toLowerCase() === 'production';

export const isStaging = (): boolean =>
  process.env.GATSBY_ENV.toLowerCase() === 'staging';

export const isPreview = (): boolean =>
  process.env.GATSBY_ENV.toLowerCase() === 'preview';

export const env = {
  recaptchaSiteKey: process.env.GATSBY_RECAPTCHA_SITE_KEY,
  stripePk: process.env.GATSBY_STRIPE_PUBLISHABLE_KEY,
  bucketStatic: process.env.GATSBY_FIREBASE_BUCKET_STATIC,
  firebaseRegion: process.env.GATSBY_FIREBASE_REGION,
  crispWebsiteId: process.env.GATSBY_CRISP_WEBSITE_ID,
  apiHost: process.env.GATSBY_API_HOST,
  feedspace: {
    generalFeedback: '7nLmacNKi5efv4Qr',
    broker: 'FyQUZTzNFm4SKNeH',
    screener: 'l4XGvuU2yKqyRSMY',
    dividendsGrowth: 'kfVNCMJQ9HTuIO1I',
    upcomingDividends: 'IhPbXJOlP11ij7f4',
    warrenBuffett: 't70OeuwDCbduAqpy',
    pennyStocks: 'WND27PAvtoSbY62f',
    gainersAndLosers: '0fwSEKYMrmhGg76I',
    investorean: 'E9cAacN8cfsjNhxT',
    indicesScreener: 'paPwvT7jhO37SMYk',
  },
};
