import { Component, type ReactNode } from 'react';
import { Link } from 'gatsby';
import { toaster, Message } from 'rsuite';
import Logger from '../../utils/Logger';
import { INTERNAL_URIS } from '../../constants/navigation';

class ErrorBoundary extends Component<
  { children: ReactNode },
  { hasError: boolean }
> {
  state = { hasError: false };

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, _errorInfo: unknown) {
    Logger.error(error);
    toaster.push(
      <Message showIcon type="error" title="Error">
        Oups, something went wrong... We will analyze and fix the issue as soon
        as possible.
      </Message>,
      { placement: 'topEnd' },
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <Message showIcon type="error" title="Error">
          <span>Oups, something went wrong...</span>
          <br />
          <span>
            Please try to refresh the page. If it does not help please
          </span>
          &nbsp;
          <Link
            to={INTERNAL_URIS.contact.path}
            title={INTERNAL_URIS.contact.title}
          >
            report us
          </Link>
          &nbsp;
          <span>the issue.</span>
        </Message>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
