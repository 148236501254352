import type { FC } from 'react';
import { Logo, OfflineContainer, OfflineMessage } from './styles';
import { ReactComponent as OfflineIcon } from '../../../static/icons/offline.svg';
import { ReactComponent as InvestoreanLogo } from '../../../static/investorean-logo-square-transparent.svg';

export const Offline: FC = () => {
  return (
    <OfflineContainer>
      <Logo>
        <InvestoreanLogo width={40} />
        <span>Investorean</span>
      </Logo>
      <OfflineIcon width={120} height={120} fill="#ffffff" />
      <OfflineMessage>
        Oops! We&apos;re temporarily offline — or maybe it's you? 😉
        <br />
        Check your connection, and we'll be back before you know it! 🚀
      </OfflineMessage>
    </OfflineContainer>
  );
};
