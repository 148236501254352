import type { FireOptions } from './types';
import { isBrowser } from '../env';

type MessageType = 'event'; // Add more values if needed

declare global {
  interface Window {
    uetq?: Array<unknown>;
  }
}

class MicrosoftUET {
  private uetq: Array<unknown>;

  constructor() {
    if (isBrowser()) {
      window.uetq = window.uetq || [];
    }
    this.uetq = (isBrowser() && window.uetq) || [];
  }

  public push(
    message: MessageType,
    eventName: string,
    options: FireOptions = {},
  ): void {
    this.uetq.push(message, eventName, options);
  }
}

export default new MicrosoftUET();
